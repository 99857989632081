import React, {useEffect, useState} from "react";
import './../componentsHomepage/homepagestyles.scss'

const Lessons= (props) => {

    return(
        <>
            <div className='textPagesWrapper'>
                <div className='flex'><p className='navigationFont underline pr-[6px] cursor-pointer' id='home' onClick={props.navigationPages}>Главная  </p> <p className='navigationFont'>> Участие в проекте&ensp;</p>  <p className='navigationFont'>> Виды занятий</p></div>
                <div className='textPages'>
                    <p className='bigH--1'>Виды занятий</p>
                    <p className='header-p-light mt-[20px]'>Предлагаемые в рамках летней школы курсы делятся на три секции: русскоязычную и англоязычную, а также специальную (например, в 2021 году специальная секция была посвящена изучению комплаенса, а в 2022 году – проводится Хакатон LegalTech для будущих юристов и программистов). </p>
                    <p className='header-p-light mt-[20px]'> &bull;Русскоязычная группа пройдет уникальные, разработанные специально для Летней школы интерактивные курсы, например: «Как писать законы» или «Юридическая техника и дизайн» и другие; <br/> <br/>
                        &bull;Англоязычная группа сможет погрузиться в интерактивные курсы с лучшими преподавателями из разных стран, изучая тонкости доказывания и выступления в суде, договорной практики, переговоров и многое другое; <br/> <br/>
                        &bull;Проект Хакатон LegalTech — это новая уникальная группа для совместного обучения юристов и программистов. Будущие юристы изучат новые тренды в юридической профессии и практике, освоят самые продвинутые LegalTech решения при помощи их разработчиков, программисты в рамках мастер-классов смогут прокачать навыки, которые им пригодятся для совместной с юристами разработки собственного уникального продукта в сфере LegalTech. </p>
                    <p className='header-p-light mt-[20px]'></p>
                    <p className='header-p-light mt-[20px]'>Программа Хакатона предусматривает 6 дней обучения и 2 дня хакатона, который завершится презентацией шестью командами (2 юриста + 3 программиста в каждой) своей воплощенной идеи. Будут определены победители, комиссия экспертов вручит призы (стажировки, возможность доработки продукта и др.). Проект проходит при грантовой поддержке Росмолодежи.</p>
                    <p className='header-p-light mt-[20px]'>Занятия во всех секциях проходят параллельно (в одно время, но в разных аудиториях). При подаче заявки студент решает для себя, на каком языке он хочет изучать основное (требуемое для получения сертификата) количество курсов. Это определит то, в какую секцию студент будет записан.
                        <br/> <br/>
                        Летняя школа предлагает разнообразные форматы проведения занятий, что делает учебный процесс не только практикоориентированным, но и весьма увлекательным.</p>
                    <p className='bigH--1 mt-[30px]'>Курсы</p>
                    <p className='header-p-light mt-[20px]'>Все курсы, предлагаемые в Летней школе, построены таким образом, что студенты имеют возможность не только получить знания в области, например, ведения переговоров и медиации или подготовки юридических документов, но смогут сами тут же применить полученные знания: принять участие в модельных процессах, выступить перед аудиторией, написать проект закона и т.д.</p>
                    <p className='bigH--1 mt-[30px]'>Обеденные семинары (Brown Bag)</p>
                    <p className='header-p-light mt-[20px]'>Brownbag (рус. «коричневый пакет») — свободное по форме проведения неформальное обсуждение, посвященное какой-либо отдельной особенно актуальной и интересной для будущего юриста теме (например, гонорарная практика адвоката).
                        <br/> <br/>
                        Brownbag-семинары обычно занимают один час. Традиционно они назначаются в обеденное время, и участники (студенты и преподаватели) приносят с собой свой ланч (бутерброды, пицца, сэндвичи и т.п., а также напитки). В нашей летней школе оргкомитет обеспечивает ланч на вынос, который доставляется в аудиторию.
                        <br/> <br/>
                        Название данного формата пришло из США. Обычно участники таких семинаров приносят с собой еду в пакетах из плотной светло-коричневой упаковочной бумаги, имеющих распространение в США, что и дало название самим семинарам.</p>
                    <p className='bigH--1 mt-[30px]'>Официальные приемы (Reception)</p>
                    <p className='header-p-light mt-[20px]'>Распространенный в США вид семинара, на котором студенты практикуют навыки лоббирования какого-либо юридического вопроса, путем проведения кратких переговоров с несколькими оппонентами. В ходе семинара моделируется некий официальный прием, основной темой обсуждения на котором является какой-то спорный законопроект. В приеме принимают участие представители законодательного органа, а также сторонники и противники обсуждаемой проблемы, чьи роли играют сами студенты.
                        <br/> <br/>
                        Несмотря на то, что в России нет практики официального лоббирования законопроектов, участие в таком семинаре позволяет студентам выработать навыки четкого и краткого изложения своей позиции, развития системы аргументации в процессе обсуждения проблемы, а также убеждения собеседника.
                        <br/> <br/>
                        В официальном приеме могут принимать участие студенты как из русскоязычной, так и из англоязычной секций.</p>
                    <p className='bigH--1 mt-[30px]'>Видео-семинар с международной юридической фирмой White&Case</p>
                    <p className='header-p-light mt-[20px]'>Летняя школа предоставляет уникальную возможность студентам пообщаться с представителями одной из ведущих международных юридических фирм — White&Case. Занятия, которые проводят ведущие юристы фирмы, проходят в формате видео-конференции. Богатейшая практика специалистов фирмы позволяет поделиться со студентами наиболее интересными и насущными проблемами профессиональной этики юриста-консультанта.
                        <br/> <br/>
                        Видео-семинары со студентами также проводят представители ведущих компаний, работающих в сфере LegalTech.</p>
                    <p className='bigH--1 mt-[30px]'>Онлайн семинар со студентами юридической клиники Джоржтаунского университета (Вашингтон, США)</p>
                    <p className='header-p-light mt-[20px]'>Это занятие представляет собой уникальную возможность для англоговорящих студентов принять участие в семинаре по проблемам живого права (Street law) – правовое просвещение — совместно со студентами-юристами Джоржтаунского университета. Занятие проводит либо нынешний руководитель юридической клиники «Street Law», профессор Харизма Хауэлл или ее основатель и экс-руководитель — профессор Ричард Роу.  </p>
                    <p className='bigH--1 mt-[30px]'>Социальная программа</p>
                    <p className='header-p-light mt-[20px]'>Летняя школа предлагает студентам не только интересные и напряженные будни, но и возможность посмотреть город Канта, узнать о всех цветах янтарной радуги и, конечно же, побродить по дюнам в Национальном парке «Куршская Коса».
                        <br/> <br/>
                        Традиционно дирекция школы организовывает для всех студентов и преподавателей тимбилдинг — совместный выезд на целый день к берегам Балтийского моря. Там участникам предлагается активный отдых: командные соревнования, пляжные спортивные игры, вкусный обед на открытом воздухе и, для желающих, незабываемое купание в Балтийском море. Каждый год места для тимбилдинга выбираются новые и, конечно же, всегда очень интересные.</p>



                </div>

            </div>
        </>
    );
};

export default Lessons;