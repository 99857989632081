import React, {useEffect, useState} from "react";
import './../componentsHomepage/homepagestyles.scss'
import news from "../../img/newsphoto.svg";
import Serv from "../../services/serv";
import letter from './../../img/letter.svg'
import ExtraEducation from "./ExtraEducation";
import Newswrapper from "../componentsNews/Newswrapper";
import anonimus from "../../img/anonimus.webp";


const AdministrationProfile= (props) => {

    const id = props.id;
    const serv = new Serv()
    const [dataUser, setDataUser] = useState([])
    const [addEducation, setAddEducation] = useState([])
    const [bfuEducation, setBfuEducation] =useState([])

    useEffect(() => {
        if(id !== undefined){
            serv.getTeacherProfile(id).then(res =>{
                setDataUser(res.data.teacherInfo)
                setAddEducation(res.data.teacherInfo.addEducation)
                setBfuEducation(res.data.teacherInfo.course)
            });
        }
    },[]);


    const elementsExtraEducation = addEducation.map((item, i) => {
        if (item.prop !== '' && item.university !== '' && item.years !== ''){
            return (<ExtraEducation data={item} key={i} />)
        }
    })

    const elementsBfuCourse = bfuEducation.map((item, i) => {
        if (item.prop !== '' && item.type !== '') {
            return (<ExtraEducation data={item} key={i} />)
        }
    })

    return(
        <>
            <div className='FormWrapper'>
                <div className='flex'><p className='navigationFont underline pr-[5px] cursor-pointer' id='home' onClick={props.navigationPages}>О школе </p>  <p className='navigationFont'>> Наши сотрудники</p></div>

                <div className='profileFirstLine'>
                    <div className='flex flex-row justify-between w-full'>
                        { (dataUser.image_path !== '' && dataUser.image_path !== null) &&
                           <div className='boxphotoprofile'> <img src={dataUser.image_path} alt="photo" className='photoProfile'/></div>
                        }
                        { (dataUser.image_path === '' || dataUser.image_path === null) &&
                            <div className='boxphotoprofile'><img src={anonimus} alt="photo" className='photoProfile'/></div>
                        }
                    <div className='profileFirstInfo'>
                        <p className='bigH pb-[12px]'>{dataUser.full_name}</p>
                        <p className='header-p-light'>{dataUser.jobTitle}</p>
                    </div>
                        <div className='vertborder'></div>
                        <div className=' profileSecondInfo'>
                            <p className='bigH--16px'>Контакты</p>
                            <div className='flex'>
                            <p className='header-p-light pt-[10px]'>{dataUser.contacts} </p>
                            </div>
                        </div>
                    </div>
                </div>

                {dataUser.teacherInfo !== '' &&
                    <div className='profileSecondLine'>
                        <div className='profileHeader'>
                            <p className='bigH--25 pb-[5px]'>Информация</p>
                            <hr/>
                        </div>
                        <p className='header-p-light--20 infoParagraph leading-7'>{dataUser.teacherInfo}</p>
                    </div>
                }

                {dataUser.education !== '' &&
                <div className='profileSecondLine'>
                    <div className='profileHeader'>
                    <p className='bigH--25 pb-[5px]'>Образование</p>
                    <hr/>
                    </div>
                    <p className='header-p-light--20 infoParagraph leading-7'>{dataUser.education}</p>
                </div>
                }

                { addEducation.length !== 0 &&
                <div className='profileSecondLine'>
                    <div className='profileHeader'>
                        <p className='bigH--25 pb-[5px]'>Ключевые программы доп. образования</p>
                        <hr/>
                    </div>
                    <div className='infoParagraph flex mb-[30px]'>
                    <hr className='hr1'/>
                    <hr className='hr2'/>
                    </div>
                    {elementsExtraEducation}
                </div>
                }

                { dataUser.interest !== '' &&
                <div className='profileSecondLine'>
                    <div className='profileHeader'>
                        <p className='bigH--25 pb-[5px]'>Профессиональные и научные интересы</p>
                        <hr/>
                    </div>
                    <p className='header-p-light--20 infoParagraph'>{dataUser.interest} </p>
                </div>
                }

                { bfuEducation.length !== 0 &&
                <div className='profileSecondLine'>
                    <div className='profileHeader'>
                        <p className='bigH--25 pb-[5px]'>Курсы и дисциплины в БФУ им. И. Канта</p>
                        <hr/>
                    </div>
                    <div className='infoParagraph flex mb-[30px]'>
                        <hr className='hr1'/>
                        <hr className='hr2'/>
                    </div>
                    {elementsBfuCourse}
                </div>
                }
                { dataUser.membership !== '' &&
                <div className='profileSecondLine pb-[100px]'>
                    <div className='profileHeader'>
                        <p className='bigH--25 pb-[5px]'>Членство в профессиональных сообществах</p>
                        <hr/>
                    </div>
                    <p className='header-p-light--20 infoParagraph'>{dataUser.membership}</p>
                </div>
                }
            </div>
        </>
    );
};

export default AdministrationProfile;