import React, {useEffect, useState} from "react";
import './../componentsHomepage/homepagestyles.scss'
import anonimus from './../../img/anonimus.webp'

const AdministratorWrapper = (props) => {

    const {full_name, image_path, job_title, id} = props.data;

    function showProfile(){
        props.setTeachersRender()
        props.setTeachersPageToRender(id)
    }

    return(
        <>
            <div className='admSchool w-[288px]' onClick={showProfile}>
                <div className='profileinfo'>
                    { (image_path !== '' && image_path !== null) &&
                    <div className='boxprofileicon'><img src={image_path} alt='photo' className='profileicon--1'/></div>
                    }
                    { (image_path === '' || image_path === null) &&
                        <div className='boxprofileicon'><img src={anonimus} alt='photo' className='profileicon--1'/></div>
                    }
                    <div className='flex flex-col'>
                        <h1>{full_name}</h1>
                        <p>{job_title}</p>
                    </div>
                </div>
            </div>
        </>
    );
};

export default AdministratorWrapper;