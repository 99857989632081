import React, {useEffect, useState} from "react";
import './../componentsHomepage/homepagestyles.scss'

const Certificates= (props) => {

    return(
        <>
            <div className='textPagesWrapper'>
                <div className='flex'><p className='navigationFont underline pr-[6px] cursor-pointer' id='home' onClick={props.navigationPages}>Главная  </p> <p className='navigationFont'>> Участие в проекте&ensp;</p>  <p className='navigationFont'>> Дипломы и сертификаты</p></div>
                <div className='textPages'>
                <p className='bigH--1'>Дипломы и сертификаты</p>
                <p className='header-p-light mt-[20px]'>Сертификат о прохождении обучения в Летней школе получат те участники, которые выполнят следующие требования: <br/> <br/>

                      &bull; Посещение занятий по всем курсам в своей секции <br/>
                      &bull; Набрать не менее 40 баллов из 100 по каждому курсу </p>
                <p className='bigH--16px mt-[30px]'>Порядок определения лучшего студента</p>
                <p className='header-p-light mt-[20px]'>По результатам обучения в Летней школе определяется 10% лучших студентов в каждой группе, которым выдается диплом с отличием. Для его получения необходимо набрать не менее 80 баллов по каждому курсу. <br/> <br/>

                    Кроме того, отдельным призом отмечается лучший студент школы — студент, набравший наибольшее количество баллов по всем изучаемым дисциплинам.
                    <br/> <br/>
                    Студенты, не набравшие указанное выше минимальное количество баллов по обязательным предметам или пропустившие более 10% занятий, не получают сертификат. Таким студентам выдается справка об участии в школе.
                    <br/> <br/>
                    Если студент пропустил более 60% учебного времени в школе, ему не выдается никакого подтверждающего документа.</p>

                </div>
                </div>
        </>
    );
};

export default Certificates;