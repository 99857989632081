import React from "react";
import logo from './logo.svg';
import './App.css';
import {Routes, Route} from "react-router-dom";
import {Home} from "./pages/Home";
import {Admin} from "./pages/Admin";
import {AdminMenu} from "./pages/Admin-Menu";

function App() {
  return (
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/admin" element={<Admin />} />
        <Route path="/admin/menu" element={<AdminMenu />} />
      </Routes>
  );
}

export default App;
