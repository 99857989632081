import './../componentsHomepage/homepagestyles.scss'
import down from './../../img/downBtn.svg'
import React, {useEffect, useRef, useState} from 'react';
import closebtn from './../../img/closeBtn.svg'


const PopUpInfo = (props) => {

    const [counterPopUp, setCounterPopUp] = useState(false)

    function onClosePopUp() {
            props.handleClose();
    }

    const rootEl = useRef(null);

    useEffect(() => {
        if(counterPopUp){
        const onClickСlose = e => rootEl.current.contains(e.target) || onClosePopUp();
        document.addEventListener('click', onClickСlose);
        return () => document.removeEventListener('click', onClickСlose);
        } else {
            setCounterPopUp(true)
        }
    }, [counterPopUp]);


    return (
        <>
            <div className="popup-box" >
                <div className='cardWrapper--popUp--1' ref={rootEl}>
                    <img src={closebtn} alt="logo" className='closeBtn' onClick={onClosePopUp}/>
                    <p>Международная летняя школа «Профессиональные навыки юриста» при БФУ им. И. Канта ежегодно проводится для российских и иностранных студентов с 2012 года. <br/> <br/>
                        Программа школы предлагает уникальный набор курсов, специально разработанных и ориентированных на развитие профессиональных и коммуникативных навыков будущих юристов. <br/> <br/>
                        Школа профессиональных навыков юриста в Балтийском
                        федеральном университете им. И. Канта: <br/>
                        • единственная в России школа для студентов, которая предлагает набор
                        Интерактивных курсов и на русском, и на английском языках <br/>
                        • школа, в программе которой уникальный набор специально разработанных
                        курсов, которые не входят в стандартные образовательные программы наших вузов <br/>
                        • единственная школа, где будущим юристам преподают профессора из ведущих
                        образовательных учреждений России, Греции, США, Армении, Голландии,
                        Узбекистана и других стран <br/>
                        • единственная школа, где Вы будете учиться у практиков, имеющих богатый
                        профессиональный опыт в области юриспруденции, программирования, дизайна,
                        дипломатии со всего мира <br/>
                        • аналогов программы летней школы в БФУ им. И. Канта нет ни в России, ни в
                        Европе <br/> <br/>
                        Школа профессиональных навыков в Калининграде — это
                        образование мирового уровня в России!
                        Здесь вы научитесь: <br/>
                        • готовить законопроекты и управлять юридической фирмой <br/>
                        • принимать решения в сложных бизнес-ситуациях <br/>
                        • разберетесь с тонкостями юридической аргументации и составления процессуальных
                        документов <br/>
                        • сможете писать договоры на английском языке и сформировать свою гонорарную
                        политику <br/>
                        • будете знать, как дальше развиваться профессионально и узнаете, что же на самом
                        деле представляет собой профессиональная этика юриста, а также попробуете себя в
                        разработке продуктов LegalTech <br/>
                        • научитесь работать с доказательствами и выступать в суде!
                        Здесь соберутся лучшие студенты со всей страны и из-за рубежа — у нас уже больше 800
                        выпускников. <br/><br/>
                        С 2022 года на платформе школы стартовал уникальный проект Хакатон LegalTech: профилактика идеологии терроризма при грантовой поддержке Росмолодежи. Впервые на площадке БФУ им. И. Канта студенты-юристы и студенты-программисты изучают различные курсы и принимают участие в тренингах и мастер-классах, посвященных прикладным проблемам развития юридических технологий, совместной работе над IT-проектами в юридической сфере. Итогом мероприятия является двухдневный хакатон, в рамках которого команды, состоящие из будущих юристов и программистов, вместе решают одну из задач по профилактике идеологии терроризма, поставленных перед ними организаторами.
                    </p>
                </div>
            </div>

        </>
    );
};

export default PopUpInfo;