import './../componentsHomepage/homepagestyles.scss'
import down from './../../img/downBtn.svg'
import {useEffect, useRef, useState} from 'react';
import closebtn from './../../img/closeBtn.svg'


const PopUp = (props) => {

    const {name, image_path, description, review} = props.data;

    const [counterPopUp, setCounterPopUp] = useState(false)

    function onClosePopUp() {
        props.handleClose();
    }

    const rootEl = useRef(null);

    useEffect(() => {
        if(counterPopUp){
            const onClickСlose = e => rootEl.current.contains(e.target) || onClosePopUp();
            document.addEventListener('click', onClickСlose);
            return () => document.removeEventListener('click', onClickСlose);
        } else {
            setCounterPopUp(true)
        }
    }, [counterPopUp]);

    return (
        <>
            <div className="popup-box" >
                <div className='cardWrapper--popUp' ref={rootEl}>
                    <div className='profileinfo'>
                        <img src={image_path} alt='logo' className='profileicon' />
                        <div className='flex flex-col'>
                            <h1>{name}</h1>
                            <p>{description}</p>
                        </div>
                        <img src={closebtn} alt="logo" className='closeBtn' onClick={onClosePopUp}/>
                    </div>
                    <p>{review}</p>
                </div>
            </div>

        </>
    );
};

export default PopUp;