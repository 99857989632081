import React from "react";
import {useState, useEffect} from "react";
import './../components/componentsHomepage/homepagestyles.scss'
import Footer from "../components/componentsFooter/Footer";
import Header from "../components/componentsHeader/Header";
import News from "../components/componentsNews/News";
import women from './../img/women.svg'
import news from  './../img/newsphoto.svg'
import bgc from  './../img/cardBg.svg'
import fludas from './../img/fludas.svg'
import arrR from './../img/arrow_right.svg'
import arrL from './../img/arrow_left.svg'
import patulin from './../img/patulin.svg'
import osipova from './../img/osipova.svg'
import turbo from './../img/turbocontact.svg'
import botman from './../img/botman.svg'
import centerR from './../img/center.svg'
import whitecase from './../img/whitecase.svg'
import locate from './../img/location.svg'
import calendar from  './../img/calendar.svg'
import letterframe from './../img/letterframe.svg'
import resumeframe from  './../img/resumeframe.svg'
import arrowright from  './../img/arrow-right-link.svg'
import play from './../img/play.svg'

import firsthidden from './../img/select/1.svg'
import firstselected from './../img/select/1s.svg'
import secondhidden from './../img/select/2h.svg'
import secondselected from './../img/select/2s.svg'
import secondselectedthirdselected from './../img/select/2s3s.svg'
import thirdhidden from './../img/select/3h.svg'
import thirdselected from './../img/select/3s.svg'
import thirdselectedfourthselected from './../img/select/3s4s.svg'
import fourthhidden from './../img/select/4h.svg'
import fourthselected from './../img/select/4s.svg'
import uploadPhotoimg from './../img/uploadPhoto.svg'
import Serv from "../services/serv";
import Housing from "../components/componentsParticipate/Housing";
import Conditions from "../components/componentsParticipate/Conditions";
import Certificates from "../components/componentsParticipate/Certificates";
import Lessons from "../components/componentsParticipate/Lessons";
import Newswrapper from "../components/componentsNews/Newswrapper";
import FullNews from "../components/componentsNews/FullNews";
import Review from "../components/componentsHomepage/ElementsReviews";
import Administration from "../components/componentsAdministration/Administration";
import Photos from "../components/componentsPhotos/Photos";
import PopUp from "../components/componentsPopUp/PopUp";
import AdministrationProfile from "../components/componentsAdministration/AdministrationProfile";
import PopUpInfo from "../components/componentsPopUp/PopUpInfo";
import Schools from "../components/conponentsSchools/Schools";


export const Home = () => {
    const serv = new Serv();
    const [newsData, setNewsData] = useState([])
    const [reviews, setRevievs] = useState([])
    const [teachers, setTeachers] = useState([])
    const [pageRender, setPageRender] = useState(0)
    const [openFilter, setOpenFilter] = useState(0)
    const [selectorImages, setSelectorImages] = useState([firsthidden, secondhidden, thirdhidden, fourthhidden])
    const [hackaton, setHackaton] = useState(false)
    const [lockInput, setLockInput] = useState(false)
    const [formValid, setFormValid] = useState(false)
    const [addinput, setaddinput] = useState('')

    const [rusFirstname , setrusFirstname] = useState('')
    const [engFirstname , setengFirstname] = useState('')
    const [rusSecname, setrusSecname ] =  useState('')
    const [engSecname , setengSecname ] = useState('')
    const [rusPatronymic , setrusPatronymic ] = useState('')
    const [engPatronymics , setengPatronymics ] = useState('')
    const [number, setnumber ] = useState('')
    const [email , setemail ] = useState('')
    const [rusUniversity, setrusUniversity ] = useState('')
    const [engUniversity , setengUniversity ] = useState('')
    const [faculty , setfaculty ] = useState('')
    const [programStudy , setprogramStudy ] = useState('')
    const [courseNumber , setcourseNumber ] = useState('')
    const [language , setlanguage ] = useState('')
    const [userChoiceGroup , setuserChoiceGroup ] = useState('')
    const [teamName , setteamName ] = useState('')
    const [teacher, setteacher] = useState('')
    const [foodRestrictions, setfoodRestrictions  ] = useState('')
    const [hotelNeed , sethotelNeed ] = useState('')
    const [doc, setdoc] = useState(null)
    const [letter, setletter] = useState(null)
    const [uploadPhoto, setUploadPhoto] = useState(null)
    const [newsPageToRender, setNewsPageToRender] = useState(0)

    const [timeForm, setTimeForm] = useState('')
    const [formClose, setFormClose] = useState(true)

    const [isOpen, setIsOpen] = useState(false);
    const [outputOnPopUp, setOutputOnPopUp] = useState([])


    const [errorsForm, setErrorsForm] = useState('')

    const [isOpen1, setIsOpen1] = useState(false);


    const togglePopup1 = () => {
        setIsOpen1(!isOpen1);
    }

    const togglePopup = () => {
        setIsOpen(!isOpen);
    }

    function checkboxes(e){
        if(e.target.value === 'Хакатон LegalTech'){
        if(hackaton){
            setHackaton(false)
        } else{ setHackaton(true)}
        }
        if(userChoiceGroup.indexOf(e.target.value) === -1){
                setuserChoiceGroup(prevState => prevState + " " + e.target.value)
        } else {
            setuserChoiceGroup(userChoiceGroup.replace(' ' + e.target.value, ''))
        }
    }

    function navigationPages(e){
        if(e.target.id === 'home'){setPageRender(0);}
        if(e.target.id === 'formPage'){setPageRender(1);}
        if(e.target.id === 'news'){setPageRender(2);}
        if(e.target.id === 'housing'){setPageRender(3);}
        if(e.target.id === 'conditions'){setPageRender(4);}
        if(e.target.id === 'certificates'){setPageRender(5);}
        if(e.target.id === 'lessons'){setPageRender(6);}
        if(e.target.id === 'administration'){setPageRender(8);}
        if(e.target.id === 'photo'){setPageRender(9);}
        if(e.target.id === 'schools'){setPageRender(10);}
    }

    function selectorView(e){
        if(e.target.id === '0'){
            setSelectorImages([firsthidden, secondhidden, thirdhidden, fourthhidden])
            setOpenFilter(0)
        }
        if(e.target.id === '1'){
            setSelectorImages([firstselected, secondselected, thirdhidden, fourthhidden])
            setOpenFilter(1)
        }
        if(e.target.id === '2'){
            setSelectorImages([firstselected, secondselectedthirdselected, thirdselected, fourthhidden])
            setOpenFilter(2)
        }
        if(e.target.id === '3'){
            setSelectorImages([firstselected, secondselectedthirdselected, thirdselectedfourthselected, fourthselected])
            setOpenFilter(3)
        }
    }

    useEffect(() => {
        serv.getHomepageInfo().then(res => {
            setNewsData(res.data.news)
            setRevievs(res.data.reviews)
            setTeachers(res.data.teachers)
        });

        if(pageRender === 1){
            serv.getTimeForm().then(res => {
                setTimeForm(res.data)
                setFormClose(res.data.isForm)
            });
        }
    },[pageRender]);

    useEffect(() => {
        if(foodRestrictions === 'Другое'){
            setLockInput(true)
        } else if(foodRestrictions === 'Нет' || foodRestrictions === 'Не ем мясо' || foodRestrictions === 'Не ем свинину' || foodRestrictions === 'Не ем рыбу' || foodRestrictions === 'Вегетарианец'){
            setLockInput(false)
            setaddinput('')
        }
    }, [foodRestrictions, addinput, lockInput]);


    function onSubmitForm(){
        if(addinput !== ''){ setfoodRestrictions(addinput) }
        setuserChoiceGroup(userChoiceGroup.replace('', ''))
        const formData = new FormData()
        formData.append('doc', doc)
        formData.append('letter', letter)
        formData.append('photo', uploadPhoto)
        if(rusUniversity !== '') {formData.append('rusUniversity', rusUniversity)}
        if(engUniversity !== '') {formData.append('engUniversity', engUniversity)}
        if(faculty !== '') {formData.append('faculty', faculty)}
        if(programStudy !== '') { formData.append('programStudy', programStudy)}
        if(courseNumber !== '') {formData.append('courseNumber', courseNumber)}
        if(language !== '') { formData.append('language', language)}
        if(userChoiceGroup !== '') { formData.append('userChoiceGroup', userChoiceGroup)}
        if(teamName !== '') { formData.append('teamName', teamName)}
        if(foodRestrictions !== '') { formData.append('foodRestrictions', foodRestrictions)}
        if(hotelNeed !== '') { formData.append('hotelNeed', hotelNeed)}
        if(rusFirstname !== '') { formData.append('rusFirstname', rusFirstname)}
        if(engFirstname !== '') { formData.append('engFirstname', engFirstname)}
        if(rusSecname !== '') { formData.append('rusSecname', rusSecname)}
        if(engSecname !== '') { formData.append('engSecname', engSecname)}
        if(email !== '') { formData.append('email', email)}
        if(number !== '') { formData.append('number', number)}
        if(engPatronymics !== '') { formData.append('engPatronymics', engPatronymics)}
        if(rusPatronymic !== '') { formData.append('rusPatronymic', rusPatronymic)}


        serv.onSubmitForm(formData).then(res => {
            if(res.error !== ""){
                setErrorsForm('Произошла ошибка. \r\n\r\n Проверьте корректность введённых данных.')
            } else if(res.data.isCreated) {setErrorsForm('Форма успешно отправлена! \r\n\r\n Спасибо за заявку!')}
        });
    }

    useEffect(() => {
        if(pageRender !== 1){
            setErrorsForm('')
        }
    },[pageRender]);

    const elementsNews = newsData.map((item, i) => {
        return (<Newswrapper data={item} key={i} margin={true} setNewsRender={() => setPageRender(7)} setNewsPageToRender={setNewsPageToRender}/>)
    })

    const elementsReviews = reviews.map((item, i) => {
        return (<Review data={item} key={i} setOutputOnPopUp={setOutputOnPopUp} togglePopup={togglePopup}/>)
    })

    return (
        <>
            {isOpen && <PopUp
                handleClose={togglePopup}
                data = {outputOnPopUp}
            />}

            {isOpen1 && <PopUpInfo
                handleClose={togglePopup1}
            />}

            <div className='backgrounds'>
            <div className='page-whapper'>
                <div className='content-wrapper'>
                    <Header navigationPages={navigationPages} />
                    {/*Стартовая страница*/}
                    {/*Стартовая страница*/}
                    {/*Стартовая страница*/}
                    { pageRender===0 &&
                        <>
                        <div className='welcomePagewrapper'>
                            <div>
                            <div className='w-[730px] flex flex-row flex-wrap mb-[30px]'><p className='bigH--50 mr-[6px]'>Добро пожаловать в Международную Летнюю школу для будущих юристов!</p></div>
                                <div className='flex flex-row items-center pt-[10px] '>
                                    <p className='header-p-light w-[540px] mb-[20px]' onClick={togglePopup1}>Международная летняя школа «Профессиональные навыки юриста» при БФУ им. И. Канта ежегодно проводится для российских и иностранных студентов с 2012 года.
                                        Программа школы ...</p><img src={arrowright} alt="logo" className='cursor-pointer w-[12px] h-[12px] mt-[17px]' onClick={togglePopup1}/></div>
                                <a href="https://disk.yandex.ru/i/fMv5nYK6zrqkDw"> <img src={play} alt="play"/></a>

                                <button className='participate-btn mt-[50px]' id='formPage' onClick={navigationPages}>Подать заявку</button>
                            </div>
                            <img src={women} alt='logo' className='pb-[50px]'/>
                        </div>
                            <div className=''>
                                <div>
                                <div className='flex flex-row items-center pt-[50px] '> <p className='bigH--40  cursor-pointer' id='news' onClick={navigationPages}>Новости</p><img id='news' src={arrowright} alt="logo" className='ml-[15px] mt-[5px] cursor-pointer' onClick={navigationPages}/></div>
                                    <div className='newsrow mt-[40px]'>
                                        { newsData.length !== 0 &&
                                            <>
                                                {elementsNews}
                                            </>
                                        }
                                    </div>
                                </div>
                            </div>

                            <div className='quickNews--1 my-[30px]'>
                                <p className='mr-[500px]'>.</p>
                                <div>
                                <div className='flex justify-between items-center'>
                                <p className='bigH--40 pt-[20px] pb-[20px]'>Отзывы учеников <br/> &emsp;&emsp;&emsp; и преподавателей</p>
                                </div>
                                <div className='wrapperReviews pr-[10px]'>
                                    {elementsReviews}
                                </div>
                                </div>
                            </div>
                            <div>
                                <div className='flex flex-row items-center pt-[20px] '> <p className='bigH--40  cursor-pointer' id='administration' onClick={navigationPages}>Администрация школы</p><img id='administration' onClick={navigationPages} src={arrowright} alt="logo" className='ml-[15px] mt-[5px] cursor-pointer'/></div>

                                <div className='flex flex-row flex-nowrap justify-between'>
                                    { teachers.length !== 0 &&
                                        <>
                                    <div className='admSchool'>
                                        <div className='profileinfo'>
                                            <img className='profileicon' src={teachers[0].image_path} alt='logo'/>
                                            <div className='flex flex-col'>
                                                <h1>{teachers[0].full_name}</h1>
                                                <p>   {teachers[0].job_title}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='admSchool'>
                                        <div className='profileinfo'>
                                            <img src={teachers[1].image_path} alt='logo' className='profileicon'/>
                                            <div className='flex flex-col'>
                                                <h1>{teachers[1].full_name}</h1>
                                                <p>   {teachers[1].job_title}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='admSchool'>
                                        <div className='profileinfo'>
                                            <img src={teachers[2].image_path} alt='logo' className='profileicon'/>
                                            <div className='flex flex-col'>
                                                <h1>{teachers[2].full_name}</h1>
                                                <p>{teachers[2].job_title}</p>
                                            </div>
                                        </div>
                                    </div>
                                        </>
                                    }
                                </div>
                            </div>

                            <div className='partners'>
                                <div className='flex justify-between items-center pt-[55px] pb-[28px]'>
                                <p className='bigH--40 '>Наши партнеры</p>
                                <div className='flex items-center'>
                                    <img className='w-[32px] h-[32px] mr-[5px] cursor-pointer' src={arrL} alt='logo'/>
                                    <img className='w-[32px] h-[32px] cursor-pointer' src={arrR} alt='logo'/>
                                </div>
                                </div>

                                <div className='flex flex-row justify-between mx-[50px]'>
                                    <img src={turbo} alt='logo'/>
                                    <img src={botman} alt='logo'/>
                                    <img src={centerR} alt='logo'/>
                                </div>
                            </div>
                        </>
                    }




                    {/*подача заявки*/}
                    {/*подача заявки*/}
                    {/*подача заявки*/}

                    {pageRender===1 &&
                        <>


                            <div className='FormWrapper'>
                                <div className='flex'><p className='navigationFont underline pr-[5px] cursor-pointer' id='home' onClick={navigationPages}>Главная </p>  <p className='navigationFont'>> Подача заявки</p></div>
                                <p className='bigH--1 w-[540px] pt-[45px]'> {timeForm.formTitle}</p>
                                <p className='bigH--1 pt-[15px]'> {timeForm.endDateString} года</p>
                                <div className='flex flex-row pb-[10px] pt-[30px]'>
                                    <img className='pr-[11px]' src={locate}/>
                                    <p className='navigationFont'>г. Калининград, ул. Невского 14</p>
                                </div>
                                <div className='flex flex-row'>
                                    <img className='pr-[11px]' src={calendar}/>
                                    <p className='navigationFont'>Форма закрывается {timeForm.endTime}</p>
                                </div>
                                <hr className='block my-[4px] mobx:my-0 w-[65%] mt-[30px]'/>
                                { formClose &&
                                    <div className='selectForm'>
                                        <img id='0' className='cursor-pointer' onClick={selectorView}
                                             src={selectorImages[0]} alt='logo'/>
                                        <img id='1' className='cursor-pointer' onClick={selectorView}
                                             src={selectorImages[1]} alt='logo'/>
                                        <img id='2' className='cursor-pointer' onClick={selectorView}
                                             src={selectorImages[2]} alt='logo'/>
                                        <img id='3' className='cursor-pointer' onClick={selectorView}
                                             src={selectorImages[3]} alt='logo'/>
                                    </div>
                                }

                                {errorsForm !== '' &&
                                    <>
                                        <p className='bigH--40 mt-[50px] text-center'>{errorsForm}</p>
                                        <button className='nextstep-btn my-[50px]' id='1' onClick={() => {setErrorsForm(''); setPageRender(0)} }>На главную</button>
                                    </>
                                }

                                { errorsForm === '' &&
                                <div className='formInfoWrapper'>
                                    { formClose === false &&
                                        <p className='bigH'>Форма регистрации закрыта</p>
                                    }
                                    { formClose &&
                                        <>
                                    {openFilter === 0 &&
                                        <>
                                        <p className='bigH--16px pb-[45px]'>Для начала нам понадобится Ваша контактная
                                            информация</p>
                                        <div className='inputlist'>
                                        <input placeholder='Фамилия на русском языке' value={rusFirstname} className='mr-[20px] mt-[2px]' onChange={(e) => setrusFirstname(e.target.value)}/>
                                        <input placeholder='Фамилия на английском языке' value={engFirstname} className='mt-[2px]' onChange={(e) => setengFirstname(e.target.value)}/>
                                        <input placeholder='Имя на русском языке' className='mr-[20px]' value={rusSecname} onChange={(e) => setrusSecname(e.target.value)}/>
                                        <input placeholder='Имя на английском языке' value={engSecname} onChange={(e) => setengSecname(e.target.value)}/>
                                        <input placeholder='Отчество на русском языке' value={rusPatronymic} className='mr-[20px]' onChange={(e) => setrusPatronymic(e.target.value)}/>
                                        <input placeholder='Отчество на английском языке' value={engPatronymics} onChange={(e) => setengPatronymics(e.target.value)}/>
                                        <input placeholder='Номер телефона' className='mr-[20px]' value={number} onChange={(e) => setnumber(e.target.value)}/>
                                        <input placeholder='Электронная почта' value={email} onChange={(e) => setemail(e.target.value)}/>
                                        </div>
                                        <button className='nextstep-btn' id='1' onClick={selectorView}>Следующий шаг!</button>
                                        </>
                                    }

                                    {openFilter === 1 &&
                                        <>
                                            <p className='bigH--16px pb-[45px]'>Расскажите про Ваше обучение в университете</p>
                                            <div className='inputlist--second'>
                                                <input placeholder='Название ВУЗа на русском языке' value={rusUniversity} onChange={(e) => setrusUniversity(e.target.value)}/>
                                                <input placeholder='Название ВУЗа на английском языке' value={engUniversity} onChange={(e) => setengUniversity(e.target.value)}/>
                                                <input placeholder='Название факультета' value={faculty} onChange={(e) => setfaculty(e.target.value)}/>
                                                <input placeholder='Программа обучения' value={programStudy} onChange={(e) => setprogramStudy(e.target.value)}/>
                                                <input type='number' placeholder='Номер курса' value={courseNumber} onChange={(e) => setcourseNumber(e.target.value)}/>
                                            </div>
                                            <div className='flex items-center justify-start w-full'><p className='form-p pl-[20px]'>Какими иностранными языками Вы владеете?</p></div>
                                            <div className='flex flex-row justify-between w-full px-[20px] mb-[20px] mt-[15px]'>
                                                <section className='flex justify-between w-full'>
                                                <input type='radio' id='11' name='language' checked={language === 'Русский'} value='Русский' className='mr-[2px] checkbox-form radiochecked' onChange={(e) => setlanguage(e.target.value)}></input> <label htmlFor='11' className='footer-h--16'>Русский</label>
                                                <input type='radio' id='22' name='language' checked={language === 'Английский intermediate'} value='Английский intermediate' className='mr-[2px] checkbox-form radiochecked' onChange={(e) => setlanguage(e.target.value)}></input> <label htmlFor='22' className='footer-h--16'>Английский, intermediate </label>
                                                <input type='radio' id='33' name='language' checked={language === 'Английский advanced'} value='Английский advanced' className='mr-[2px] checkbox-form radiochecked' onChange={(e) => setlanguage(e.target.value)}></input> <label htmlFor='33' className='footer-h--16'>Английский, advanced  </label>
                                                <input type='radio' id='44' name='language' checked={language === 'Иные'} value='Иные' className='mr-[2px] checkbox-form radiochecked' onChange={(e) => setlanguage(e.target.value)}></input> <label htmlFor='44' className='footer-h--16'>Иные</label>
                                                </section>
                                            </div>
                                            <button className='nextstep-btn' id='2' onClick={selectorView}>Следующий шаг!</button>

                                        </>
                                    }

                                    {openFilter === 2 &&
                                        <>
                                            <p className='bigH--16px pb-[30px]'>Какое обучение Вы бы хотели пройти в рамках школы?</p>
                                            <div className='flex items-center justify-start w-full'><p className='form-p pl-[20px] pb-[15px]'>В какой группе Вы бы хотели обучаться?</p></div>
                                            <div className='w-full'>
                                            <section className='sectionboxes pl-[20px]'>
                                                <div className='flex flex-row justify-between items-center'>
                                                    <div className='inputlabel'><input type="checkbox" id='111' checked={userChoiceGroup.indexOf('Русскоязычной группе') !== -1} className='checkboxchecked' value='Русскоязычной группе' onChange={checkboxes}/> <label htmlFor='111' className='footer-h--16'>Русскоязычной группе</label></div>
                                                    <div className='inputlabel'><input type="checkbox" id='222' checked={userChoiceGroup.indexOf('Англоязычной группе') !== -1} className='checkboxchecked' value='Англоязычной группе' onChange={checkboxes}/> <label htmlFor='222' className='footer-h--16'>Англоязычной группе</label></div>
                                                </div>
                                                <div className='flex flex-row justify-between items-center'>
                                                    <div className='inputlabel'><input type="checkbox" id='333' checked={userChoiceGroup.indexOf('Хакатон LegalTech') !== -1} className='checkboxchecked' value='Хакатон LegalTech' onChange={checkboxes}/> <label htmlFor='333' className='footer-h--16'>Хакатон LegalTech</label></div>
                                                    <div className='inputlabel'><input type="checkbox" id='4441' checked={(userChoiceGroup.indexOf('Устроит любая группа') !== -1)} className='checkboxchecked' value='Устроит любая группа' onChange={checkboxes}/> <label htmlFor='4441' className='footer-h--16'>Устроит любая группа</label></div>
                                                </div>
                                                </section>
                                            </div>
                                            {hackaton &&
                                                <>
                                                < div className='flex items-center justify-start w-full'><p className='form-p pl-[20px] pb-[15px] pt-[20px]'>Есть ли у Вас уже название своей команды?</p></div>
                                                <section className='sectionboxes mr-[80px]'>
                                                <input type='text' value={teamName} onChange={(e) => setteamName(e.target.value)}/>
                                                </section>
                                                </>
                                            }
                                            <div className='flex items-center justify-start w-full'><p className='form-p pl-[20px] pb-[15px] pt-[20px]'>Если Вас рекомендует преподаватель, то укажите его ФИО, должность и контакты</p></div>
                                            <section className='sectionboxes mr-[80px]'>
                                                <input type='text' value={teacher} onChange={(e) => setteacher(e.target.value)}/>
                                            </section>

                                            <div className='flex items-center justify-start w-full'><p className='form-p pl-[20px] pb-[15px] pt-[35px]'>Есть ли у вас ограничения по питанию?</p></div>
                                            <div className='w-full'>
                                            <section className='sectionboxes pl-[20px]'>
                                                <div className='flex flex-row justify-between items-center'>
                                                    <div className='inputlabel'><input type='radio' id='123' name='food' checked={foodRestrictions === 'Нет'} value='Нет' className='mr-[2px] checkbox-form radiochecked' onChange={(e) => setfoodRestrictions(e.target.value)}></input> <label htmlFor='123' className='footer-h--16'>Нет</label></div>
                                                    <div className='inputlabel'><input type='radio' id='223' name='food' checked={foodRestrictions === 'Не ем рыбу'} value='Не ем рыбу' className='mr-[2px] checkbox-form radiochecked' onChange={(e) => setfoodRestrictions(e.target.value)}></input> <label htmlFor='223' className='footer-h--16'>Не ем рыбу</label></div>
                                                </div>
                                                <div className='flex flex-row justify-between items-center'>
                                                    <div className='inputlabel'><input type='radio' id='323' name='food' checked={foodRestrictions === 'Не ем мясо'} value='Не ем мясо' className='mr-[2px] checkbox-form radiochecked' onChange={(e) => setfoodRestrictions(e.target.value)}></input> <label htmlFor='323' className='footer-h--16'>Не ем мясо</label></div>
                                                    <div className='inputlabel'><input type='radio' id='423' name='food' checked={foodRestrictions === 'Вегетарианец'} value='Вегетарианец' className='mr-[2px] checkbox-form radiochecked' onChange={(e) => setfoodRestrictions(e.target.value)}></input> <label htmlFor='423' className='footer-h--16'>Вегетарианец</label></div>
                                                </div>
                                                <div className='flex flex-row justify-between items-center'>
                                                    <div className='inputlabel'><input type='radio' id='523' name='food' checked={foodRestrictions === 'Не ем свинину'} value='Не ем свинину' className='mr-[2px] checkbox-form radiochecked' onChange={(e) => setfoodRestrictions(e.target.value)}></input> <label htmlFor='523' className='footer-h--16'>Не ем свинину</label></div>
                                                    <div className='inputlabel'><input type='radio' id='623' name='food' checked={foodRestrictions === 'Другое'} value='Другое' className='mr-[2px] checkbox-form radiochecked' onChange={(e) => setfoodRestrictions(e.target.value)}></input> <label htmlFor='623' className='footer-h--16 mr-[10px]'>Другое:</label><input disabled={!lockInput} value={addinput} onChange={(e) => setaddinput(e.target.value)}/></div>
                                                </div>
                                            </section>
                                            </div>

                                            <div className='flex items-center justify-start w-full'><p className='form-p pl-[20px] pb-[15px] pt-[35px]'>Нуждаетесь ли Вы в расселении в партнерском хостеле "Education"? </p></div>
                                            <div className='w-full pl-[20px]'>
                                                <section className='flex flex-col'>
                                                    <div className='flex items-center mb-[15px]'><input type='radio' id='134' name='hotelstay' checked={hotelNeed === 'Нет не нуждаюсь'} value='Нет не нуждаюсь' className='mr-[5px] checkbox-form radiochecked' onChange={(e) => sethotelNeed(e.target.value)}></input> <label htmlFor='134' className='footer-h--16' >Нет, не нуждаюсь</label> </div>
                                                    <div className='flex items-center mb-[15px]'><input type='radio' id='234' name='hotelstay' checked={hotelNeed === 'Да мне нужно расселение в даты Летней школы'} value='Да мне нужно расселение в даты Летней школы' className='mr-[5px] checkbox-form radiochecked' onChange={(e) => sethotelNeed(e.target.value)}></input> <label htmlFor='234' className='footer-h--16' >Да, мне нужно расселение в даты Летней школы </label> </div>
                                                    <div className='flex items-center mb-[15px]'><input type='radio' id='334' name='hotelstay' checked={hotelNeed === 'Да мне нужно расселение но даты укажу отдельно в письме в оргкомитет'} value='Да мне нужно расселение но даты укажу отдельно в письме в оргкомитет' className='mr-[5px] checkbox-form radiochecked' onChange={(e) => sethotelNeed(e.target.value)}></input> <label htmlFor='334' className='footer-h--16' >Да, мне нужно расселение, но даты укажу отдельно в письме в оргкомитет  </label> </div>
                                                </section>
                                            </div>
                                            <button className='nextstep-btn mt-[30px]' id='3' onClick={selectorView}>Следующий шаг!</button>

                                        </>
                                    }

                                    {openFilter === 3 &&
                                        <>
                                            <p className='bigH--16px pb-[45px]'>Прикрепите Ваше резюме, фото и мотивационное письмо</p>
                                            <div className='inputlist--second'>
                                                <input type='file' id='letter' className='hidden' onChange={(e) => setletter(e.target.files[0])}/>
                                                <label htmlFor="resume" className='mr-[10px]'><img src={resumeframe} className='cursor-pointer w-[190px] h-[190px]' alt='logo'></img> {(doc !== null && doc !== undefined) && <p className='form-p text-center mt-[10px]'>{doc.name}</p>}</label>
                                                <input type="file" id='resume' className='hidden' onChange={(e) => setdoc(e.target.files[0])}/>
                                                <label htmlFor="letter"><img src={letterframe} className='cursor-pointer w-[190px] h-[190px] mr-[10px]' alt='logo'/> {(letter !== null && letter !== undefined) && <p className='form-p text-center mt-[10px]'>{letter.name}</p>} </label>
                                                <input type="file" id='photo' className='hidden' onChange={(e) => setUploadPhoto(e.target.files[0])}/>
                                                <label htmlFor="photo"><img src={uploadPhotoimg} className='cursor-pointer w-[190px] h-[190px]' alt='logo'/> {(uploadPhoto !== null && uploadPhoto !== undefined) && <p className='form-p text-center mt-[10px]'>{uploadPhoto.name}</p>} </label>
                                            </div>

                                            <div className='flex items-center justify-start w-full'><p className='form-p pl-[20px] pb-[15px] pt-[20px]'>Я даю согласие на обработку моих персональных данных в целях, необходимых для организации и проведения летней школы</p></div>
                                            <section className='sectionboxes w-full'><div className='flex justify-center items-center mb-[15px]'> <input type='checkbox' id='17' checked={formValid} className='checkboxchecked mr-[10px]' onClick={() => setFormValid(!formValid)}/> <label htmlFor="17" className='footer-h--16'>Да</label></div></section>
                                            <button disabled={!formValid || !letter || !doc || !uploadPhoto} className={(!formValid || !letter || !doc || !uploadPhoto) ? ('nextstep-btn-disabled') : ('nextstep-btn')} id='2' onClick={onSubmitForm}>{(!formValid || !letter || !doc || !uploadPhoto) ? ('Недоступно') : ('Готово!')}</button>

                                        </>
                                    }
                                        </>
                                    }
                                </div>
                                }
                            </div>
                        </>
                    }

                    {/*новости новости*/}
                    {/*новости новости*/}
                    {/*новости новости*/}


                    {pageRender === 2 &&
                        <>
                            <News navigationPages={navigationPages}/>
                        </>
                    }

                    {pageRender === 3 &&
                        <>
                            <Housing navigationPages={navigationPages}/>
                        </>
                    }

                    {pageRender === 4 &&
                        <>
                            <Conditions navigationPages={navigationPages}/>
                        </>
                    }

                    {pageRender === 5 &&
                        <>
                            <Certificates navigationPages={navigationPages}/>
                        </>
                    }

                    {pageRender === 6 &&
                        <>
                            <Lessons navigationPages={navigationPages}/>
                        </>
                    }

                    {pageRender === 7 &&
                        <>
                            <FullNews navigationPages={navigationPages} id={newsPageToRender}/>
                        </>
                    }

                    {pageRender === 8 &&
                        <>
                            <Administration navigationPages={navigationPages}/>
                        </>
                    }

                    {pageRender === 9 &&
                        <>
                            <Photos navigationPages={navigationPages}/>
                        </>
                    }

                    {pageRender === 10 &&
                        <>
                            <Schools navigationPages={navigationPages}/>
                        </>
                    }
                </div>
                <footer className='footer'>
                    <Footer navigationPages={navigationPages}/>
                </footer>
            </div>
            </div>
        </>
    );
};