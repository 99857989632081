import React, {useEffect, useState} from "react";
import './../componentsHomepage/homepagestyles.scss'
import down from './../../img/downBtn.svg'

const Review = (props) => {

    const {name, image_path, description, review} = props.data;



    return(
        <>
            <div className='cardWrapper'>
                { review.length >= 530 &&
                <div className='gradient' onClick={() => {props.setOutputOnPopUp(props.data); props.togglePopup()}}><img src={down} alt="logo"/></div>
                }
                <div className='profileinfo'>
                    <img src={image_path} alt='logo' className='profileicon'/>
                    <div className='flex flex-col'>
                        <h1>{name}</h1>
                        <p>{description}</p>
                    </div>
                </div>
                <p>{review}</p>
            </div>
        </>
    );
};

export default Review;