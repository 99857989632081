import React, {useEffect, useState} from "react";
import './../componentsHomepage/homepagestyles.scss'
import Serv from "../../services/serv";
import Newswrapper from "./Newswrapper";
import FullNews from "./FullNews";

const News= (props) => {

    const serv = new Serv();
    const [newsData, setNewsData] = useState([])
    const [countOfPages, setCountOfPages] = useState(1)
    const [perPage, setPerPage] = useState(9)
    const [currentPage, setCurrentPage] = useState(1)
    const [fetching, setFetching] = useState(true)
    const [newsRender, setNewsRender] = useState(0)
    const [newsPageToRender, setNewsPageToRender] = useState(0)


    useEffect(() => {
        if(fetching){
            if(currentPage <= countOfPages) {
                serv.getAllNews(currentPage).then(res => {
                    setNewsData([...newsData, ...res.data.news.data]);
                    setCountOfPages(res.data.pageCount)
                    setPerPage(res.data.news.per_page)
                    if (currentPage === 1) {
                        setCurrentPage(2)
                    } else {
                        setCurrentPage(prevState => prevState + 1)
                    }

                })
                    .finally(() => setFetching(false))
            }
        }
    }, [currentPage, fetching, countOfPages]);

    useEffect(() => {
        document.addEventListener('scroll', scrollHandler);
        return function () {
            document.removeEventListener('scroll', scrollHandler);
        };
    },[]);

    const scrollHandler = (e) => {
        if(e.target.documentElement.scrollHeight - (e.target.documentElement.scrollTop + window.innerHeight) < 100){
            setFetching(true);
        }
    }

    const elementsNews = newsData.map((item, i) => {
        return (<Newswrapper data={item} key={i} margin={newsData.length === 2} setNewsRender={() => setNewsRender(1)} setNewsPageToRender={setNewsPageToRender} /> )
    })



    return(
        <>
            { newsRender === 0 &&
            <div className='FormWrapper'>
                <div className='flex'><p className='navigationFont underline pr-[5px] cursor-pointer' id='home' onClick={props.navigationPages}>Главная </p>  <p className='navigationFont'>> Все новости</p></div>
                <div className='flex justify-between flex-wrap pt-[45px]'>
                {elementsNews}
                </div>
            </div>
            }

            { newsRender === 1 &&
                <FullNews id={newsPageToRender} navigationPages={() => setNewsRender(0)}/>
            }
        </>
    );
};

export default News;