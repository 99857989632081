import React, {useEffect, useState} from "react";
import './../componentsHomepage/homepagestyles.scss'
import Serv from "../../services/serv";
import folderImg from './../../img/folder.svg'
import downloadIcon from './../../img/download.svg'
import file from './../../img/file.svg'

const Schools = (props) => {

    const serv = new Serv()

    const [dataUser, setDataUser] = useState([])
    const [filesFolder, setFilesFolder] = useState([])
    const [filesRender, setFilesRender] = useState('0')

    useEffect(() => {
        serv.getFolders().then(res => {
            setDataUser(res.data.folders)
        });
    }, []);

    function getfiles(e){
        setFilesRender('1')
        serv.getFiles(e.target.id).then(res => {
            setFilesFolder(res.data.files)
        });
    }

    function download(e){
        serv.downloadFile(e.target.id).then(res => {
        });
    }

//  const elementsPhots = photos.map((item, i) => {
//      return (<PhotosWrapper data={item} key={i} />)
//  })


    return(
        <>
            <div className='FormWrapper'>
                { filesRender === '0' &&
                    <div className='flex'><p className='navigationFont underline pr-[6px] cursor-pointer' id='home' onClick={props.navigationPages}>Главная  </p> <p className='navigationFont'>> О школе&ensp;</p>  <p className='navigationFont'>> Файлы школы</p></div>
                }
                { filesRender === '1' &&
                    <div className='flex'><p className='navigationFont underline pr-[6px] cursor-pointer' id='home' onClick={props.navigationPages}>Главная  </p> <p className='navigationFont'>> О школе&ensp;>&ensp;</p>  <p className='navigationFont underline cursor-pointer' onClick={() => setFilesRender('0')}>Файлы школы</p></div>
                }
                <p className='bigH--40 w-full pt-[50px]'>Файлы школы</p>

                <div className='flex justify-between flex-row w-full mt-[40px]'>
                <p className='navigationFont--30 ml-[2px]'>Название</p>
                <p className='navigationFont--30 pr-[220px]'>Год добавления</p>
                </div>
                <hr className='hr3 mt-[8px]'/>



                { filesRender === '0' &&
                        dataUser.map((item) => {
                            return (
                                <div className='flex justify-between flex-col w-full items-start'>
                                    <div className='flex flex-row justify-between w-full mt-[20px]'>
                                        <div className='flex'>
                                            <img src={folderImg} id={item.id} alt="logo" className='w-[20px] h-[20px] mr-[18px] cursor-pointer' onClick={getfiles}/>
                                            <p className='header-p-light w-[400px] cursor-pointer' id={item.id} onClick={getfiles}>{item.name}</p>
                                        </div>
                                        <p className='header-p-light'>{item.year}</p>
                                        <div className='w-[20px] h-[20px] cursor-pointer'></div>
                                    </div>
                                    <hr className='hr3 mt-[10px]'/>
                                </div>
                            )
                        })
                }

                { filesRender === '1' &&
                    filesFolder.map((item) => {
                        return (
                            <div className='flex justify-between flex-col w-full items-start'>
                                <div className='flex flex-row justify-between w-full mt-[20px]'>
                                    <div className='flex'>
                                        <img src={file} alt="logo" className='w-[20px] h-[20px] mr-[18px]' />
                                        <p className='header-p-light w-[400px]'>{item.name} </p>
                                    </div>
                                    <p className='header-p-light'>{item.year}</p>
                                    <div><a href={item.link} download> <img src={downloadIcon} id={item.id} alt="logo" className='w-[20px] h-[20px] cursor-pointer' onClick={download} /> </a> </div>
                                </div>
                                <hr className='hr3 mt-[10px]'/>
                            </div>
                        )
                    })
                }

            </div>
        </>
    );
};

export default Schools;