import React from "react";
import {useState, useEffect} from "react";
import './../components/componentsAdmin/adminStyles.scss'
import Serv from "../services/serv";
import {useNavigate} from "react-router-dom";





export const AdminMenu = () => {

    const serv = new Serv();
    const navigate = useNavigate();

    const [flag, setFlag] = useState(false)
    const [flag1, setFlag1] = useState(false)

    const [dopStudy, setDopStudy] = useState([{ year: "", university: "", dopStudyProp: ""},{ year: "", university: "", dopStudyProp: ""},{ year: "", university: "", dopStudyProp: ""},{ year: "", university: "", dopStudyProp: ""},{ year: "", university: "", dopStudyProp: ""},{ year: "", university: "", dopStudyProp: ""},{ year: "", university: "", dopStudyProp: ""}])
    const [course, setCourse] = useState([{ name: "", courseProp: ""},{ name: "", courseProp: ""},{ name: "", courseProp: ""},{ name: "", courseProp: ""},{ name: "", courseProp: ""},{ name: "", courseProp: ""},{ name: "", courseProp: ""}])

    const [firstDopStudy,setFirstDopStudy] = useState(['','',''])
    const [secondDopStudy,setSecondDopStudy] = useState(['','',''])
    const [thirdDopStudy,setThirdDopStudy] = useState(['','',''])
    const [fourthDopStudy,setFourthDopStudy] = useState(['','',''])
    const [fifthDopStudy,setFifthDopStudy] = useState(['','',''])
    const [sixthDopStudy,setSixthDopStudy] = useState(['','',''])
    const [seventhDopStudy,setSeventhDopStudy] = useState(['','',''])

    const [firstCourse,setFirstCourse] = useState(['','',''])
    const [secondCourse,setSecondCourse] = useState(['','',''])
    const [thirdCourse,setThirdCourse] = useState(['','',''])
    const [fourthCourse,setFourthCourse] = useState(['','',''])
    const [fifthCourse,setFifthCourse] = useState(['','',''])
    const [sixthCourse,setSixthCourse] = useState(['','',''])
    const [seventhCourse,setSeventhCourse] = useState(['','',''])

    const [fullName, setFullName] = useState('')
    const [jobTitle, setJobTitle] = useState('')
    const [study, setStudy] = useState('')
    const [role, setRole] = useState('')
    const [contacts, setContacts] = useState('')
    const [interests, setInterests] = useState('')
    const [membership, setMembership] = useState('')
    const [information, setInformation] = useState('')
    const [progress, setProgress] = useState('')
    const [imgTeacher, setImgTeacher] = useState('')




    function onCreateNews(e) {
        e.preventDefault()
        const formData = e.target.parentElement
        var data = new FormData(formData)
        serv.onCreateNews(localStorage.getItem('token'), data).then(res => {
        });
    }

    function onCreateReview(e) {
        e.preventDefault()
        const formData = e.target.parentElement
        var data = new FormData(formData)
        serv.onCreateReview(localStorage.getItem('token'), data).then(res => {
        });
    }



    function onCreatePhoto(e) {
        e.preventDefault()
        const formData = e.target.parentElement
        var data = new FormData(formData)
        serv.onCreatePhoto(localStorage.getItem('token'), data).then(res => {
        });
    }

    function onChangeForm(e) {
        e.preventDefault()
        const formData = e.target.parentElement
        var data = new FormData(formData)
        serv.onChangeForm(localStorage.getItem('token'), data).then(res => {
        });
    }

    function onCreateFile(e) {
        e.preventDefault()
        const formData = e.target.parentElement
        var data = new FormData(formData)
        serv.onCreateFile(localStorage.getItem('token'), data).then(res => {
        });
    }

    function onCreateTeacher() {
        const formData = new FormData()
        if(imgTeacher!=='' && imgTeacher !== undefined){formData.append('image', imgTeacher)}
        if(!course.every((e) => (e.name === '' && e.courseProp === ''))) {formData.append('course', JSON.stringify({course}))}
        if(!dopStudy.every((e) => (e.dopStudyProp === '' && e.year === '' && e.university === ''))){formData.append('dopStudy', JSON.stringify({dopStudy}))}
        if(fullName!==''){formData.append('fullName', fullName)}
        if(jobTitle!==''){formData.append('jobTitle', jobTitle)}
        if(role!==''){formData.append('role', role)}
        if(progress!==''){formData.append('progress', progress)}
        if(contacts!==''){formData.append('contacts', contacts)}
        if(study!==''){formData.append('study', study)}
        if(membership!==''){formData.append('membership', membership)}
        if(information!==''){formData.append('information', information)}

        serv.onCreateTeacher(localStorage.getItem('token'), formData).then(res => {
        });
    }

    useEffect(() => {
        setDopStudy([{ year: firstDopStudy[0], university: firstDopStudy[1], dopStudyProp: firstDopStudy[2]},{ year: secondDopStudy[0], university: secondDopStudy[1], dopStudyProp: secondDopStudy[2]},{ year: thirdDopStudy[0], university: thirdDopStudy[1], dopStudyProp: thirdDopStudy[2]},{ year: fourthDopStudy[0], university: fourthDopStudy[1], dopStudyProp: fourthDopStudy[2]},{ year: fifthDopStudy[0], university: fifthDopStudy[1], dopStudyProp: fifthDopStudy[2]},{ year: sixthDopStudy[0], university: sixthDopStudy[1], dopStudyProp: sixthDopStudy[2]},{ year: seventhDopStudy[0], university: seventhDopStudy[1], dopStudyProp: seventhDopStudy[2]}])
        setCourse([{ name: firstCourse[0], courseProp: firstCourse[1]},{ name: secondCourse[0], courseProp: secondCourse[1]},{ name: thirdCourse[0], courseProp: thirdCourse[1]},{ name: fourthCourse[0], courseProp: fourthCourse[1]},{ name: fifthCourse[0], courseProp: fifthCourse[1]},{ name: sixthCourse[0], courseProp: sixthCourse[1]},{ name: seventhCourse[0], courseProp: seventhCourse[1]}])
    },[firstDopStudy,secondDopStudy, thirdDopStudy, fourthDopStudy, fifthDopStudy, sixthDopStudy, seventhDopStudy, firstCourse, secondCourse, thirdCourse, fourthCourse, fifthCourse, sixthCourse, seventhCourse]);



    const [dataTeachers, setDataTeachers] = useState([]);
    const [teacherid, setTeacherid] = useState('')

    const [dopStudy1, setDopStudy1] = useState([{ year: "", university: "", dopStudyProp: ""},{ year: "", university: "", dopStudyProp: ""},{ year: "", university: "", dopStudyProp: ""},{ year: "", university: "", dopStudyProp: ""},{ year: "", university: "", dopStudyProp: ""},{ year: "", university: "", dopStudyProp: ""},{ year: "", university: "", dopStudyProp: ""}])
    const [course1, setCourse1] = useState([{ name: "", courseProp: ""},{ name: "", courseProp: ""},{ name: "", courseProp: ""},{ name: "", courseProp: ""},{ name: "", courseProp: ""},{ name: "", courseProp: ""},{ name: "", courseProp: ""}])

    const [firstDopStudy1,setFirstDopStudy1] = useState(['','',''])
    const [secondDopStudy1,setSecondDopStudy1] = useState(['','',''])
    const [thirdDopStudy1,setThirdDopStudy1] = useState(['','',''])
    const [fourthDopStudy1,setFourthDopStudy1] = useState(['','',''])
    const [fifthDopStudy1,setFifthDopStudy1] = useState(['','',''])
    const [sixthDopStudy1,setSixthDopStudy1] = useState(['','',''])
    const [seventhDopStudy1,setSeventhDopStudy1] = useState(['','',''])

    const [firstCourse1,setFirstCourse1] = useState(['','',''])
    const [secondCourse1,setSecondCourse1] = useState(['','',''])
    const [thirdCourse1,setThirdCourse1] = useState(['','',''])
    const [fourthCourse1,setFourthCourse1] = useState(['','',''])
    const [fifthCourse1,setFifthCourse1] = useState(['','',''])
    const [sixthCourse1,setSixthCourse1] = useState(['','',''])
    const [seventhCourse1,setSeventhCourse1] = useState(['','',''])

    const [fullName1, setFullName1] = useState('')
    const [jobTitle1, setJobTitle1] = useState('')
    const [study1, setStudy1] = useState('')
    const [role1, setRole1] = useState('')
    const [contacts1, setContacts1] = useState('')
    const [interests1, setInterests1] = useState('')
    const [membership1, setMembership1] = useState('')
    const [information1, setInformation1] = useState('')
    const [progress1, setProgress1] = useState('')
    const [imgTeacher1, setImgTeacher1] = useState('')

    useEffect(() => {
        setDopStudy1([{ year: firstDopStudy1[0], university: firstDopStudy1[1], dopStudyProp: firstDopStudy1[2]},{ year: secondDopStudy1[0], university: secondDopStudy1[1], dopStudyProp: secondDopStudy1[2]},{ year: thirdDopStudy1[0], university: thirdDopStudy1[1], dopStudyProp: thirdDopStudy1[2]},{ year: fourthDopStudy1[0], university: fourthDopStudy1[1], dopStudyProp: fourthDopStudy1[2]},{ year: fifthDopStudy1[0], university: fifthDopStudy1[1], dopStudyProp: fifthDopStudy1[2]},{ year: sixthDopStudy1[0], university: sixthDopStudy1[1], dopStudyProp: sixthDopStudy1[2]},{ year: seventhDopStudy1[0], university: seventhDopStudy1[1], dopStudyProp: seventhDopStudy1[2]}])
        setCourse1([{ name: firstCourse1[0], courseProp: firstCourse1[1]},{ name: secondCourse1[0], courseProp: secondCourse1[1]},{ name: thirdCourse1[0], courseProp: thirdCourse1[1]},{ name: fourthCourse1[0], courseProp: fourthCourse1[1]},{ name: fifthCourse1[0], courseProp: fifthCourse1[1]},{ name: sixthCourse1[0], courseProp: sixthCourse1[1]},{ name: seventhCourse1[0], courseProp: seventhCourse1[1]}])
    },[firstDopStudy1,secondDopStudy1, thirdDopStudy1, fourthDopStudy1, fifthDopStudy1, sixthDopStudy1, seventhDopStudy1, firstCourse1, secondCourse1, thirdCourse1, fourthCourse1, fifthCourse1, sixthCourse1, seventhCourse1]);


    useEffect(() => {
        serv.getTeacherAdmin(localStorage.getItem('token')).then(res => {
            if (res.error === 'Token has expired') {
                serv.refreshToken(localStorage.getItem('token')).then(r => {
                    localStorage.setItem('token', r.data.access_token)
                }).then(() => {
                    serv.getTeacherAdmin(localStorage.getItem('token')).then(newRes => {
                        setDataTeachers(newRes.data.teachers)
                    })
                })
            } else if (res.error === "Token has blacklisted") {
                navigate('/admin')
            } else if (res.error === "Wrong number of segments") {
                navigate('/admin')
            } else if (res.error === "Token not provided") {
                navigate('/admin')
            } else {
                setDataTeachers(res.data.teachers)
            }
        });
    }, [])


    useEffect(() => {
         if(localStorage.getItem('token') === ''){
             navigate('/admin')
         }
    }, [])


    function onUpdateTeacher() {
        const formData = new FormData()
        formData.append('teacherId', teacherid)

        if(imgTeacher1 !== '' && imgTeacher1 !== undefined){formData.append('image', imgTeacher1)}
        if(!course1.every((e) => (e.name === '' && e.courseProp === ''))) {formData.append('course', JSON.stringify({course1}))}
        if(!dopStudy1.every((e) => (e.dopStudyProp === '' && e.year === '' && e.university === ''))){formData.append('dopStudy', JSON.stringify({dopStudy1}))}
        if(fullName1 !== ''){formData.append('fullName', fullName1)}
        if(jobTitle1 !== ''){formData.append('jobTitle', jobTitle1)}
        if(role1 !== ''){formData.append('role', role1)}
        if(progress1 !== ''){formData.append('progress', progress1)}
        if(contacts1 !== ''){formData.append('contacts', contacts1)}
        if(study1 !== ''){formData.append('study', study1)}
        if(interests1 !== ''){formData.append('interests', interests1)}
        if(membership1 !== ''){formData.append('membership', membership1)}
        if(information1 !== ''){formData.append('information', information1)}

        serv.onUpdateTeacher(localStorage.getItem('token'), formData).then(res => {
        });
    }



    const [documents, setDocuments] = useState('')
    const [documentHelper, setDocumentHelper] = useState('')
    const [terms, setTerms] = useState('')
    const [termsHelper, setTermsHelper] = useState('')

    function onUpdateCondition(e) {
        serv.onUpdateCondition(localStorage.getItem('token'), documents, documentHelper, terms, termsHelper).then(res => {
        });
    }


    const [dateInfo, setDateInfo] = useState('')
    const [dateValue, setDateValue] = useState('')
    const [price, setPrice] = useState('')
    const [hotelInfo, setHotelInfo] = useState('')
    const [hotelAddress, setHotelAddress] = useState('')
    const [eatPrice, setEatPrice] = useState('')

    function onUpdateHousing(e) {
        serv.onUpdateHousing(localStorage.getItem('token'), dateInfo, dateValue, price, hotelInfo, hotelAddress, eatPrice).then(res => {
        });
    }


    return(
        <>
            <div className='page-adm-wrap'>
                <div className='Menu-Wrapper'>
                    <div className='blockWrapper'>
                        <p className='bigH mb-[20px]'>Создать новость</p>
                        <form className='flex justify-start flex-col w-[80%]' action="#" id='formElem'>
                            <p className='bigH--16px mt-[20px] ml-[5px]'>Загрузите обложку статьи: </p>
                            <input name='image' type='file' className='mt-[20px]'/>
                            <p className='bigH--16px mt-[20px] ml-[5px]'>Заголовок статьи: </p>
                            <input name='title' type='text' className='inputadmin'/>
                            <p className='bigH--16px mt-[20px] ml-[5px]'>Вставьте текст статьи: </p>
                            <textarea name='description' className='huge-input'/>
                            <p className='bigH--16px mt-[20px] ml-[5px]'>Напишите примерное время прочтения статьи: </p>
                            <input name='time' type='text' className='inputadmin mb-[30px]'/>
                            <button className='call-us-btn-1' onClick={onCreateNews}>Создать</button>
                        </form>
                    </div>

                    <div className='blockWrapper'>
                        <p className='bigH mb-[20px]'>Создать отзыв</p>
                        <form className='flex justify-start flex-col w-[80%]' action="#" id='formElem'>
                            <p className='bigH--16px mt-[20px] ml-[5px]'>Фото(с соотношением сторон 1:1) </p>
                            <input name='image' type='file' className='mt-[20px]'/>
                            <p className='bigH--16px mt-[20px] ml-[5px]'>Имя </p>
                            <input name='name' type='text' className='inputadmin'/>
                            <p className='bigH--16px mt-[20px] ml-[5px]'>Краткая информация </p>
                            <input name='description' type='text' className='inputadmin '/>
                            <p className='bigH--16px mt-[20px] ml-[5px]'> Текст отзыва </p>
                            <textarea name='review' className='huge-input mb-[30px]'/>
                            <button className='call-us-btn-1' onClick={onCreateReview}>Создать</button>
                        </form>
                    </div>

                    <div className='blockWrapper'>
                        <p className='bigH mb-[20px]'>Загрузить фото</p>
                        <form className='flex justify-start flex-col w-[80%]' action="#" id='formElem'>
                            <p className='bigH--16px mt-[20px] ml-[5px]'>Фото</p>
                            <input name='image' type='file' className='mt-[20px]'/>
                            <p className='bigH--16px mt-[20px] ml-[5px]'>Год </p>
                            <input name='date' type='text' className='inputadmin'/>
                            <button className='call-us-btn-1 mt-[20px]' onClick={onCreatePhoto}>Загрузить</button>
                        </form>
                    </div>

                    <div className='blockWrapper'>
                        <p className='bigH mb-[20px]'>Редактировать заголовок страницы "Подача заявки"</p>
                        <form className='flex justify-start flex-col w-[80%]' action="#" id='formElem'>
                            <p className='bigH--16px mt-[20px] ml-[5px]'>Заголовок </p>
                            <textarea name='formTitle' className='huge-input mb-[30px]'/>
                            <p className='bigH--16px mt-[20px] ml-[5px]'>Дата завершения сбора заявок</p>
                            <input name='endDate' type='date' className='mt-[20px] inputadmin'/>
                            <p className='bigH--16px mt-[20px] ml-[5px]'>Период (пример: 17-25 августа 2023) без слова "года" </p>
                            <input name='endString' type='text' className='inputadmin'/>
                            <button className='call-us-btn-1 mt-[20px]' onClick={onChangeForm}>Загрузить</button>
                        </form>
                    </div>

                    <div className='blockWrapper'>
                        <p className='bigH mb-[20px]'>Создать нового сотрудника</p>
                        <form className='flex justify-start flex-col w-[80%]' action="#" id='formElem'>
                            <p className='bigH--16px mt-[20px] ml-[5px]'>Полное имя</p>
                            <input name='fullName' className='mt-[20px] inputadmin' onChange={(e) => setFullName(e.target.value)}/>

                            <p className='bigH--16px mt-[20px] ml-[5px]'>Фото</p>
                            <input name='image' type='file' className='mt-[20px]' onChange={(e) => setImgTeacher(e.target.files[0])}/>

                            <p className='bigH--16px mt-[20px] ml-[5px]'> Должность в школе</p>
                            <input name='jobTitle' className='inputadmin mb-[30px] ' onChange={(e) => setJobTitle(e.target.value)} />

                            <p className='bigH--16px mt-[20px] ml-[5px]'> Регалии (вводить в столбец)</p>
                            <textarea name='jobTitle' className='huge-input mb-[30px]' onChange={(e) => setProgress(e.target.value)} />


                            <p className='bigH--16px mt-[20px] ml-[5px]'> В каком списке отображать сотрудника?</p>
                            <div className='mt-[10px]'><input className='mr-[5px]' type="radio" name='role' id='teacher' value='teacher' onChange={(e) => setRole(e.target.value)} defaultChecked/><label htmlFor='teacher'>Преподаватели</label></div>
                            <div><input className='mr-[5px]' type="radio" name='role' id='admin' value='admin' onChange={(e) => setRole(e.target.value)} /><label htmlFor='admin'>Администрация школы</label></div>

                            <p className='bigH--16px mt-[20px] ml-[5px]'> Информация о сотрудникe</p>
                            <textarea name='information' className='huge-input mb-[30px]' onChange={(e) => setInformation(e.target.value)}/>

                            <p className='bigH--16px mt-[20px] ml-[5px]'> Контакты(вводить в столбик)</p>
                            <textarea name='contacts' className='huge-input mb-[30px]' onChange={(e) => setContacts(e.target.value)}/>

                            <p className='bigH my-[20px]'>Необязательные для заполнения поля:</p>

                            <p className='bigH--16px mt-[20px] ml-[5px]'> Образование(вводить в столбик)</p>
                            <textarea name='study' className='huge-input mb-[30px]' onChange={(e) => setStudy(e.target.value)}/>

                            <p className='bigH--16px mt-[20px] ml-[5px]'> Ключевые программы доп. образования (максимум 7)</p>
                            <div className='w-full flex'>
                                <input  placeholder='года получения доп. образования' className='mt-[20px] mr-[10px] inputadmin' onChange={(e) => setFirstDopStudy([e.target.value, firstDopStudy[1], firstDopStudy[2]])}/>
                                <input  placeholder='университет (университеты)' className='mt-[20px] mr-[10px] inputadmin' onChange={(e) => setFirstDopStudy([firstDopStudy[0], e.target.value,  firstDopStudy[2]])}/>
                                <input  placeholder='полученное образование' className='mt-[20px] inputadmin' onChange={(e) => setFirstDopStudy([firstDopStudy[0], firstDopStudy[1] ,  e.target.value])}/>
                            </div>
                            <div className='w-full flex'>
                                <input  placeholder='года получения доп. образования' className='mt-[20px] mr-[10px] inputadmin' onChange={(e) => setSecondDopStudy([e.target.value, secondDopStudy[1], secondDopStudy[2] ])}/>
                                <input  placeholder='университет (университеты)' className='mt-[20px] mr-[10px] inputadmin' onChange={(e) => setSecondDopStudy([secondDopStudy[0], e.target.value, secondDopStudy[2]])}/>
                                <input  placeholder='полученное образование' className='mt-[20px] inputadmin' onChange={(e) => setSecondDopStudy([secondDopStudy[0], secondDopStudy[1], e.target.value])}/>
                            </div>
                            <div className='w-full flex'>
                                <input  placeholder='года получения доп. образования' className='mt-[20px] mr-[10px] inputadmin' onChange={(e) => setThirdDopStudy([e.target.value,thirdDopStudy[1], thirdDopStudy[2]])}/>
                                <input  placeholder='университет (университеты)' className='mt-[20px] mr-[10px] inputadmin' onChange={(e) => setThirdDopStudy([thirdDopStudy[0], e.target.value, thirdDopStudy[2]])}/>
                                <input  placeholder='полученное образование' className='mt-[20px] inputadmin' onChange={(e) => setThirdDopStudy([thirdDopStudy[0], thirdDopStudy[1], e.target.value])}/>
                            </div>
                            <div className='w-full flex'>
                                <input  placeholder='года получения доп. образования' className='mt-[20px] mr-[10px] inputadmin' onChange={(e) => setFourthDopStudy([e.target.value,fourthDopStudy[1], fourthDopStudy[2]])}/>
                                <input  placeholder='университет (университеты)' className='mt-[20px] mr-[10px] inputadmin' onChange={(e) => setFourthDopStudy([fourthDopStudy[0], e.target.value, fourthDopStudy[2]])}/>
                                <input  placeholder='полученное образование' className='mt-[20px] inputadmin' onChange={(e) => setFourthDopStudy([fourthDopStudy[0], fourthDopStudy[1], e.target.value])}/>
                            </div>
                            <div className='w-full flex'>
                                <input  placeholder='года получения доп. образования' className='mt-[20px] mr-[10px] inputadmin' onChange={(e) => setFifthDopStudy([e.target.value, fifthDopStudy[1], fifthDopStudy[2]])}/>
                                <input  placeholder='университет (университеты)' className='mt-[20px] mr-[10px] inputadmin' onChange={(e) => setFifthDopStudy([ fifthDopStudy[0], e.target.value, fifthDopStudy[2]])}/>
                                <input  placeholder='полученное образование' className='mt-[20px] inputadmin' onChange={(e) => setFifthDopStudy([ fifthDopStudy[0], fifthDopStudy[1] , e.target.value])}/>
                            </div>
                            <div className='w-full flex'>
                                <input  placeholder='года получения доп. образования' className='mt-[20px] mr-[10px] inputadmin' onChange={(e) => setSixthDopStudy([e.target.value, sixthDopStudy[1], sixthDopStudy[2]])}/>
                                <input  placeholder='университет (университеты)' className='mt-[20px] mr-[10px] inputadmin' onChange={(e) => setSixthDopStudy([ sixthDopStudy[0], e.target.value, sixthDopStudy[2]])}/>
                                <input  placeholder='полученное образование' className='mt-[20px] inputadmin' onChange={(e) => setSixthDopStudy([ sixthDopStudy[0], sixthDopStudy[1], e.target.value])}/>
                            </div>
                            <div className='w-full flex'>
                                <input  placeholder='года получения доп. образования' className='mt-[20px] mr-[10px] inputadmin' onChange={(e) => setSeventhDopStudy([e.target.value, seventhDopStudy[1], seventhDopStudy[2]])}/>
                                <input  placeholder='университет (университеты)' className='mt-[20px] mr-[10px] inputadmin' onChange={(e) => setSeventhDopStudy([ seventhDopStudy[0], e.target.value, seventhDopStudy[2]])}/>
                                <input  placeholder='полученное образование' className='mt-[20px] inputadmin' onChange={(e) => setSeventhDopStudy([ seventhDopStudy[0], seventhDopStudy[1] , e.target.value])}/>
                            </div>


                            <p className='bigH--16px mt-[20px] ml-[5px]'> Курсы и дисциплины в БФУ им. И. Канта (максимум 7)</p>
                            <div className='w-full flex'>
                                <input  placeholder='академическая степень' className='mt-[20px] mr-[10px] inputadmin' onChange={(e) => setFirstCourse([e.target.value, firstCourse[1]])}/>
                                <input  placeholder='полученное образование' className='mt-[20px] inputadmin' onChange={(e) => setFirstCourse( [firstCourse[0], e.target.value])}/>
                            </div>
                            <div className='w-full flex'>
                                <input  placeholder='академическая степень' className='mt-[20px] mr-[10px] inputadmin' onChange={(e) => setSecondCourse( [e.target.value, secondCourse[1]])}/>
                                <input  placeholder='полученное образование' className='mt-[20px] inputadmin' onChange={(e) => setSecondCourse( [secondCourse[0], e.target.value])}/>
                            </div>
                            <div className='w-full flex'>
                                <input  placeholder='академическая степень' className='mt-[20px] mr-[10px] inputadmin' onChange={(e) => setThirdCourse([e.target.value, thirdCourse[1]])}/>
                                <input  placeholder='полученное образование' className='mt-[20px] inputadmin' onChange={(e) => setThirdCourse([thirdCourse[0], e.target.value])}/>
                            </div>
                            <div className='w-full flex'>
                                <input  placeholder='академическая степень' className='mt-[20px] mr-[10px] inputadmin' onChange={(e) => setFourthCourse([e.target.value, fourthCourse[1]])}/>
                                <input  placeholder='полученное образование' className='mt-[20px] inputadmin' onChange={(e) => setFourthCourse([fourthCourse[0] , e.target.value])}/>
                            </div>
                            <div className='w-full flex'>
                                <input  placeholder='академическая степень' className='mt-[20px] mr-[10px] inputadmin' onChange={(e) => setFifthCourse([e.target.value, fifthCourse[1]])}/>
                                <input  placeholder='полученное образование' className='mt-[20px] inputadmin' onChange={(e) => setFifthCourse([ fifthCourse[0], e.target.value])}/>
                            </div>
                            <div className='w-full flex'>
                                <input  placeholder='академическая степень' className='mt-[20px] mr-[10px] inputadmin' onChange={(e) => setSixthCourse([e.target.value, sixthCourse[1]])}/>
                                <input  placeholder='полученное образование' className='mt-[20px] inputadmin' onChange={(e) => setSixthCourse([sixthCourse[0], e.target.value])}/>
                            </div>
                            <div className='w-full flex'>
                                <input  placeholder='академическая степень' className='mt-[20px] mr-[10px] inputadmin' onChange={(e) => setSeventhCourse([e.target.value, seventhCourse[1]])}/>
                                <input  placeholder='полученное образование' className='mt-[20px] inputadmin' onChange={(e) => setSeventhCourse([seventhCourse[0] ,e.target.value])}/>
                            </div>


                            <p className='bigH--16px mt-[20px] ml-[5px]'> Профессиональные и научные интересы</p>
                            <textarea name='contacts' className='huge-input mb-[30px]' onChange={(e) => setInterests(e.target.value)}/>

                            <p className='bigH--16px mt-[20px] ml-[5px]'> Членство в профессиональных сообществах</p>
                            <textarea name='contacts' className='huge-input mb-[30px]' onChange={(e) => setMembership(e.target.value)}/>

                        </form>

                        <button className='call-us-btn-1 mt-[20px]' onClick={onCreateTeacher}>Создать</button>

                    </div>


                    <div className='blockWrapper'>
                        <p className='bigH mb-[20px]'>Отредактировать профиль сотрудника</p>
                        <form className='flex justify-start flex-col w-[80%]' action="#" id='formElem'>

                            <p className='bigH--16px mt-[20px] ml-[5px] mb-[10px]'>Выберите сотрудника</p>
                            {
                                dataTeachers.map((item) => {
                                    return (<><div className='flex flex-row'> <input className='mr-[5px]' name='select' type="radio" id={item.id} value={item.id} onChange={(e) => setTeacherid(e.target.value)}/> <label htmlFor={item.id}>{item.full_name}</label> </div></>)
                                })
                            }


                            <p className='bigH--16px mt-[20px] ml-[5px]'>Полное имя</p>
                            <input name='fullName' className='mt-[20px] inputadmin' onChange={(e) => setFullName1(e.target.value)}/>

                            <p className='bigH--16px mt-[20px] ml-[5px]'>Фото</p>
                            <input name='image' type='file' className='mt-[20px]' onChange={(e) => setImgTeacher1(e.target.files[0])}/>

                            <p className='bigH--16px mt-[20px] ml-[5px]'> Должность в школе</p>
                            <input name='jobTitle' className='inputadmin mb-[30px] ' onChange={(e) => setJobTitle1(e.target.value)} />

                            <p className='bigH--16px mt-[20px] ml-[5px]'> Регалии (вводить в столбец)</p>
                            <textarea name='jobTitle' className='huge-input mb-[30px]' onChange={(e) => setProgress1(e.target.value)} />


                            <p className='bigH--16px mt-[20px] ml-[5px]'> В каком списке отображать сотрудника?</p>
                            <div className='mt-[10px]'><input className='mr-[5px]' type="radio" name='role' id='teacher1' value='teacher' onChange={(e) => setRole1(e.target.value)}/><label htmlFor='teacher1'>Преподаватели</label></div>
                            <div><input className='mr-[5px]' type="radio" name='role' id='admin1' value='admin' onChange={(e) => setRole1(e.target.value)} /><label htmlFor='admin1'>Администрация школы</label></div>

                            <p className='bigH--16px mt-[20px] ml-[5px]'> Информация о сотрудникe</p>
                            <textarea name='information' className='huge-input mb-[30px]' onChange={(e) => setInformation1(e.target.value)}/>

                            <p className='bigH--16px mt-[20px] ml-[5px]'> Контакты(вводить в столбик)</p>
                            <textarea name='contacts' className='huge-input mb-[30px]' onChange={(e) => setContacts1(e.target.value)}/>

                            <p className='bigH my-[20px]'>Необязательные для заполнения поля:</p>

                            <p className='bigH--16px mt-[20px] ml-[5px]'> Образование(вводить в столбик)</p>
                            <textarea name='study' className='huge-input mb-[30px]' onChange={(e) => setStudy1(e.target.value)}/>

                            <p className='bigH--16px mt-[20px] ml-[5px]'> Ключевые программы доп. образования (максимум 7)</p>
                            <div className='w-full flex'>
                                <input  placeholder='года получения доп. образования' className='mt-[20px] mr-[10px] inputadmin' onChange={(e) => setFirstDopStudy1([e.target.value, firstDopStudy1[1], firstDopStudy1[2]])}/>
                                <input  placeholder='университет (университеты)' className='mt-[20px] mr-[10px] inputadmin' onChange={(e) => setFirstDopStudy1([firstDopStudy1[0], e.target.value,  firstDopStudy1[2]])}/>
                                <input  placeholder='полученное образование' className='mt-[20px] inputadmin' onChange={(e) => setFirstDopStudy1([firstDopStudy1[0], firstDopStudy1[1] ,  e.target.value])}/>
                            </div>
                            <div className='w-full flex'>
                                <input  placeholder='года получения доп. образования' className='mt-[20px] mr-[10px] inputadmin' onChange={(e) => setSecondDopStudy1([e.target.value, secondDopStudy1[1], secondDopStudy1[2] ])}/>
                                <input  placeholder='университет (университеты)' className='mt-[20px] mr-[10px] inputadmin' onChange={(e) => setSecondDopStudy1([secondDopStudy1[0], e.target.value, secondDopStudy1[2]])}/>
                                <input  placeholder='полученное образование' className='mt-[20px] inputadmin' onChange={(e) => setSecondDopStudy1([secondDopStudy1[0], secondDopStudy1[1], e.target.value])}/>
                            </div>
                            <div className='w-full flex'>
                                <input  placeholder='года получения доп. образования' className='mt-[20px] mr-[10px] inputadmin' onChange={(e) => setThirdDopStudy1([e.target.value,thirdDopStudy1[1], thirdDopStudy1[2]])}/>
                                <input  placeholder='университет (университеты)' className='mt-[20px] mr-[10px] inputadmin' onChange={(e) => setThirdDopStudy1([thirdDopStudy1[0], e.target.value, thirdDopStudy1[2]])}/>
                                <input  placeholder='полученное образование' className='mt-[20px] inputadmin' onChange={(e) => setThirdDopStudy1([thirdDopStudy1[0], thirdDopStudy1[1], e.target.value])}/>
                            </div>
                            <div className='w-full flex'>
                                <input  placeholder='года получения доп. образования' className='mt-[20px] mr-[10px] inputadmin' onChange={(e) => setFourthDopStudy1([e.target.value,fourthDopStudy1[1], fourthDopStudy1[2]])}/>
                                <input  placeholder='университет (университеты)' className='mt-[20px] mr-[10px] inputadmin' onChange={(e) => setFourthDopStudy1([fourthDopStudy1[0], e.target.value, fourthDopStudy1[2]])}/>
                                <input  placeholder='полученное образование' className='mt-[20px] inputadmin' onChange={(e) => setFourthDopStudy1([fourthDopStudy1[0], fourthDopStudy1[1], e.target.value])}/>
                            </div>
                            <div className='w-full flex'>
                                <input  placeholder='года получения доп. образования' className='mt-[20px] mr-[10px] inputadmin' onChange={(e) => setFifthDopStudy1([e.target.value, fifthDopStudy1[1], fifthDopStudy1[2]])}/>
                                <input  placeholder='университет (университеты)' className='mt-[20px] mr-[10px] inputadmin' onChange={(e) => setFifthDopStudy1([ fifthDopStudy1[0], e.target.value, fifthDopStudy1[2]])}/>
                                <input  placeholder='полученное образование' className='mt-[20px] inputadmin' onChange={(e) => setFifthDopStudy1([ fifthDopStudy1[0], fifthDopStudy1[1] , e.target.value])}/>
                            </div>
                            <div className='w-full flex'>
                                <input  placeholder='года получения доп. образования' className='mt-[20px] mr-[10px] inputadmin' onChange={(e) => setSixthDopStudy1([e.target.value, sixthDopStudy1[1], sixthDopStudy1[2]])}/>
                                <input  placeholder='университет (университеты)' className='mt-[20px] mr-[10px] inputadmin' onChange={(e) => setSixthDopStudy1([ sixthDopStudy1[0], e.target.value, sixthDopStudy1[2]])}/>
                                <input  placeholder='полученное образование' className='mt-[20px] inputadmin' onChange={(e) => setSixthDopStudy1([ sixthDopStudy1[0], sixthDopStudy1[1], e.target.value])}/>
                            </div>
                            <div className='w-full flex'>
                                <input  placeholder='года получения доп. образования' className='mt-[20px] mr-[10px] inputadmin' onChange={(e) => setSeventhDopStudy1([e.target.value, seventhDopStudy1[1], seventhDopStudy1[2]])}/>
                                <input  placeholder='университет (университеты)' className='mt-[20px] mr-[10px] inputadmin' onChange={(e) => setSeventhDopStudy1([ seventhDopStudy1[0], e.target.value, seventhDopStudy1[2]])}/>
                                <input  placeholder='полученное образование' className='mt-[20px] inputadmin' onChange={(e) => setSeventhDopStudy1([ seventhDopStudy1[0], seventhDopStudy1[1] , e.target.value])}/>
                            </div>


                            <p className='bigH--16px mt-[20px] ml-[5px]'> Курсы и дисциплины в БФУ им. И. Канта (максимум 7)</p>
                            <div className='w-full flex'>
                                <input  placeholder='академическая степень' className='mt-[20px] mr-[10px] inputadmin' onChange={(e) => setFirstCourse1([e.target.value, firstCourse1[1]])}/>
                                <input  placeholder='полученное образование' className='mt-[20px] inputadmin' onChange={(e) => setFirstCourse1( [firstCourse1[0], e.target.value])}/>
                            </div>
                            <div className='w-full flex'>
                                <input  placeholder='академическая степень' className='mt-[20px] mr-[10px] inputadmin' onChange={(e) => setSecondCourse1( [e.target.value, secondCourse1[1]])}/>
                                <input  placeholder='полученное образование' className='mt-[20px] inputadmin' onChange={(e) => setSecondCourse1( [secondCourse1[0], e.target.value])}/>
                            </div>
                            <div className='w-full flex'>
                                <input  placeholder='академическая степень' className='mt-[20px] mr-[10px] inputadmin' onChange={(e) => setThirdCourse1([e.target.value, thirdCourse1[1]])}/>
                                <input  placeholder='полученное образование' className='mt-[20px] inputadmin' onChange={(e) => setThirdCourse1([thirdCourse1[0], e.target.value])}/>
                            </div>
                            <div className='w-full flex'>
                                <input  placeholder='академическая степень' className='mt-[20px] mr-[10px] inputadmin' onChange={(e) => setFourthCourse1([e.target.value, fourthCourse1[1]])}/>
                                <input  placeholder='полученное образование' className='mt-[20px] inputadmin' onChange={(e) => setFourthCourse1([fourthCourse1[0] , e.target.value])}/>
                            </div>
                            <div className='w-full flex'>
                                <input  placeholder='академическая степень' className='mt-[20px] mr-[10px] inputadmin' onChange={(e) => setFifthCourse1([e.target.value, fifthCourse1[1]])}/>
                                <input  placeholder='полученное образование' className='mt-[20px] inputadmin' onChange={(e) => setFifthCourse1([ fifthCourse1[0], e.target.value])}/>
                            </div>
                            <div className='w-full flex'>
                                <input  placeholder='академическая степень' className='mt-[20px] mr-[10px] inputadmin' onChange={(e) => setSixthCourse1([e.target.value, sixthCourse1[1]])}/>
                                <input  placeholder='полученное образование' className='mt-[20px] inputadmin' onChange={(e) => setSixthCourse1([sixthCourse1[0], e.target.value])}/>
                            </div>
                            <div className='w-full flex'>
                                <input  placeholder='академическая степень' className='mt-[20px] mr-[10px] inputadmin' onChange={(e) => setSeventhCourse1([e.target.value, seventhCourse1[1]])}/>
                                <input  placeholder='полученное образование' className='mt-[20px] inputadmin' onChange={(e) => setSeventhCourse1([seventhCourse1[0] ,e.target.value])}/>
                            </div>


                            <p className='bigH--16px mt-[20px] ml-[5px]'> Профессиональные и научные интересы</p>
                            <textarea name='contacts' className='huge-input mb-[30px]' onChange={(e) => setInterests1(e.target.value)}/>

                            <p className='bigH--16px mt-[20px] ml-[5px]'> Членство в профессиональных сообществах</p>
                            <textarea name='contacts' className='huge-input mb-[30px]' onChange={(e) => setMembership1(e.target.value)}/>

                        </form>

                        <button className='call-us-btn-1 mt-[20px]' onClick={onUpdateTeacher}>Создать</button>

                    </div>

                    <div className='blockWrapper'>
                        <p className='bigH mb-[20px]'>Загрузить файл</p>
                        <form className='flex justify-start flex-col w-[80%]' action="#" id='formElem'>
                            <p className='bigH--16px mt-[20px] ml-[5px]'>Файл </p>
                            <input name='file' type='file' className='mt-[20px]'/>
                            <p className='bigH--16px mt-[20px] ml-[5px]'>Год </p>
                            <input name='year' type='text' className='inputadmin'/>
                            <p className='bigH--16px mt-[20px] ml-[5px]'>Название папки(Если папки с таким названием не существует, создастся новая папка) </p>
                            <textarea name='folderName' className='huge-input mb-[30px]'/>
                            <button className='call-us-btn-1' onClick={onCreateFile}>Создать</button>
                        </form>
                    </div>

                    <div className='blockWrapper'>
                        <p className='bigH mb-[20px]'>Редактировать информацию в страничке "Условия подачи"</p>
                        <form className='flex justify-start flex-col w-[80%]' action="#" id='formElem'>
                            <p className='bigH--16px mt-[20px] ml-[5px]'>Заявка включает в себя следующие документы:</p>
                            <textarea className='huge-input mb-[30px]' onChange={(e) => setDocuments(e.target.value)}/>
                            <p className='bigH--16px mt-[20px] ml-[5px]'>Изменить абзац про мотивационное письмо (Мотивационное письмо предполагает эссе до 200 слов....)</p>
                            <textarea className='huge-input mb-[30px]' onChange={(e) => setDocumentHelper(e.target.value)}/>
                            <p className='bigH--16px mt-[20px] ml-[5px]'>Срок подачи заявки ("Срок подачи заявки до 15 июня 2023 года включительно.")</p>
                            <textarea className='huge-input mb-[30px]' onChange={(e) => setTerms(e.target.value)}/>
                            <p className='bigH--16px mt-[20px] ml-[5px]'>Изменить абзац про срок приема заявок ("Если Вы не получили письмо из Оргкомитета с 15 июня до 15 июля включительно...")</p>
                            <textarea className='huge-input mb-[30px]' onChange={(e) => setTermsHelper(e.target.value)}/>
                        </form>
                        <button className='call-us-btn-1' onClick={onUpdateCondition}>Создать</button>

                    </div>

                    <div className='blockWrapper'>
                        <p className='bigH mb-[20px]'>Редактировать информацию в страничке "Информация о проживании"</p>
                        <form className='flex justify-start flex-col w-[80%]' action="#" id='formElem'>
                            <p className='bigH--16px mt-[20px] ml-[5px]'>Изменить текст в жёлтой рамочке</p>
                            <textarea className='huge-input mb-[30px]' onChange={(e) => setDateInfo(e.target.value)}/>
                            <p className='bigH--16px mt-[20px] ml-[5px]'>Абзац, в котором говорится о датах проведения летней школы, а также про даты приезда и выезда </p>
                            <textarea className='huge-input mb-[30px]' onChange={(e) => setDateValue(e.target.value)}/>
                            <p className='bigH--16px mt-[20px] ml-[5px]'>Цена хостела</p>
                            <textarea className='huge-input mb-[30px]' onChange={(e) => setPrice(e.target.value)}/>
                            <p className='bigH--16px mt-[20px] ml-[5px]'>Абзац с информацией об отеле</p>
                            <textarea className='huge-input mb-[30px]' onChange={(e) => setHotelInfo(e.target.value)}/>
                            <p className='bigH--16px mt-[20px] ml-[5px]'>Адрес отеля</p>
                            <textarea className='huge-input mb-[30px]' onChange={(e) => setHotelAddress(e.target.value)}/>
                            <p className='bigH--16px mt-[20px] ml-[5px]'>Цена за питание (5000 рублей на весь период школы, включая тимбилдинг)</p>
                            <textarea className='huge-input mb-[30px]' onChange={(e) => setEatPrice(e.target.value)}/>
                        </form>
                        <button className='call-us-btn-1' onClick={onUpdateHousing}>Создать</button>
                    </div>

                </div>
            </div>
        </>
    );
};