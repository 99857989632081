import React from "react";
import {useState, useEffect} from "react";
import './../components/componentsAdmin/adminStyles.scss'
import Serv from "../services/serv";
import { useNavigate } from "react-router-dom";


export const Admin = () => {

    const serv = new Serv();
    const navigate = useNavigate()
    const [inputDataLogin, setInputDataLogin] = useState('')
    const [inputDataPassword, setInputDataPassword] = useState('')


    useEffect(() => {
        localStorage.setItem('token', '');
    }, [])

    function onSubmitData() {
        serv.onLoginAdmin(inputDataLogin, inputDataPassword).then(res => {
            localStorage.setItem('token', res.data.access_token);
        }).then(() => {
            if ( localStorage.getItem('token') !== '') {
                navigate("/admin/menu")
            }
        })
    }

    function onInputUser(e) {
        switch (e.target.name) {
            case 'login':
                setInputDataLogin(e.target.value)
                break;
            case 'password':
                setInputDataPassword(e.target.value)
                break;
            default:
                break;
        }
    }

    return(
        <>
            <div className='adminWrapper items-center'>
                    <div className='LoginContent'>
                        <p>Авторизация</p>
                        <input type="text" placeholder='Логин' name='login' onChange={onInputUser}/>
                        <input type="password" placeholder='Пароль' name='password' onChange={onInputUser}/>
                        <button className='login-btn' onClick={onSubmitData}>Войти</button>
                    </div>
            </div>
        </>
    );
};