class Serv {
    _apiBase = 'https://bfussback.ru/api/v1/';

    getResource = async (url, yourConf) => {
        let res = await fetch(url, {
            method: 'GET',
            headers: yourConf
        });
        if (!res.ok) {
            // throw new Error(`Could not fetch ${url}, status: ${res.status}`)
            // let refreshRes = await fetch(url, {
            //     method: 'GET',
            //     headers: yourConf
            // });
            // return await refreshRes.json()
        }
        return await res.json()
    }

    getAllNews = async (currentPage) => {
        const res = await this.getResource(`${this._apiBase}page/news?page=${currentPage}`);
        return res;
    }

    getHomepageInfo = async () => {
        const res = await this.getResource(`${this._apiBase}page/start`);
        return res;
    }

    getHousingInfo = async () => {
        const res = await this.getResource(`${this._apiBase}page/housing`);
        return res;
    }

    getConditionInfo = async () => {
        const res = await this.getResource(`${this._apiBase}page/condition`);
        return res;
    }

    getAdministrationInfo = async () => {
        const res = await this.getResource(`${this._apiBase}page/teachers`);
        return res;
    }

    getNewsPage = async (newsId) => {
        const res = await this.getResource(`${this._apiBase}page/news/full?newsId=${newsId}`);
        return res;
    }

    getPhotosPage = async () => {
        const res = await this.getResource(`${this._apiBase}page/gallery`);
        return res;
    }

    getTimeForm = async () => {
        const res = await this.getResource(`${this._apiBase}page/form`);
        return res;
    }

    getTeacherProfile = async (teacherId) => {
        const res = await this.getResource(`${this._apiBase}page/teachers/info?teacherId=${teacherId}`);
        return res;
    }

    getFolders = async () => {
        const res = await this.getResource(`${this._apiBase}page/folders`);
        return res;
    }

    getFiles = async (folderId) => {
        const res = await this.getResource(`${this._apiBase}page/folders/files?folderId=${folderId}`);
        return res;
    }

    downloadFile = async (fileId) => {
        const res = await this.getResource(`${this._apiBase}page/folders/files/download?fileId=${fileId}`);
        return res;
    }


    onSubmitForm = async (formData) => {
        const res = await fetch(`${this._apiBase}page/form/submit`, {
            method: "POST",
            body: formData

        });
        return await res.json()
    }



    //ниже идут операции с админкои

    onLoginAdmin = async (login, password) => {
        const res = await fetch(`${this._apiBase}admin/auth/login`, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                login,
                password
            })
        });
        return await res.json()
    }


    onCreateNews = async (token,formData) => {
        const res = await fetch(`${this._apiBase}admin/news/create`, {
            method: "POST",
            headers: {
                'Authorization': `Bearer ${token}`
            },

            body: formData

        });
        return await res.json()
    }

    onCreateReview = async (token,formData) => {
        const res = await fetch(`${this._apiBase}admin/reviews/create`, {
            method: "POST",
            headers: {
                'Authorization': `Bearer ${token}`
            },

            body: formData

        });
        return await res.json()
    }

    onCreatePhoto = async (token,formData) => {
        const res = await fetch(`${this._apiBase}admin/gallery/create`, {
            method: "POST",
            headers: {
                'Authorization': `Bearer ${token}`
            },

            body: formData

        });
        return await res.json()
    }

    onChangeForm = async (token,formData) => {
        const res = await fetch(`${this._apiBase}admin/form/create`, {
            method: "POST",
            headers: {
                'Authorization': `Bearer ${token}`
            },

            body: formData

        });
        return await res.json()
    }

    onCreateTeacher = async (token,formData) => {
        const res = await fetch(`${this._apiBase}admin/teachers/create`, {
            method: "POST",
            headers: {
                'Authorization': `Bearer ${token}`
            },

            body: formData

        });
        return await res.json()
    }

    onUpdateTeacher = async (token,formData) => {
        const res = await fetch(`${this._apiBase}admin/teachers/update`, {
            method: "POST",
            headers: {
                'Authorization': `Bearer ${token}`
            },

            body: formData

        });
        return await res.json()
    }

    onCreateFile = async (token,formData) => {
        const res = await fetch(`${this._apiBase}admin/files/create`, {
            method: "POST",
            headers: {
                'Authorization': `Bearer ${token}`
            },

            body: formData

        });
        return await res.json()
    }

    onUpdateCondition = async (token,documents, documentHelper, terms, termsHelper) => {
        const res = await fetch(`${this._apiBase}admin/condition/update`, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify({
                documents,
                documentHelper,
                terms,
                termsHelper
            })
        });
        return await res.json()
    }

    onUpdateHousing = async (token,dateInfo, dateValue, price, hotelInfo, hotelAddress, eatPrice) => {
        const res = await fetch(`${this._apiBase}admin/housing/update`, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify({
                dateInfo,
                dateValue,
                price,
                hotelInfo,
                hotelAddress,
                eatPrice
            })
        });
        return await res.json()
    }

    getTeacherAdmin = async (token) => {
        const headersConfig = new Headers();
        headersConfig.append('Authorization', `Bearer ${token}`);
        const res = await this.getResource(`${this._apiBase}admin/teachers`, headersConfig);
        return res;
    }

    refreshToken = async (token) => {
        const headersConfig = new Headers();
        headersConfig.append('Authorization', `Bearer ${token}`);
        const res = await this.getResource(`${this._apiBase}admin/auth/refresh`, headersConfig);
        return res;
    }


}

export default Serv;