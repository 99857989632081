import React, {useState, useRef, useEffect} from "react";
import './../componentsHomepage/homepagestyles.scss'
import logo from "./../../img/logo.svg"

const Header= (props) => {
    const [opentab, setOpenTab] = useState(0)

    const rootEl = useRef(null);
    const rootEl1 = useRef(null);
    const rootEl2 = useRef(null);



    useEffect(() => {
        const onClick = e => (rootEl.current.contains(e.target) || rootEl1.current.contains(e.target) || rootEl2.current.contains(e.target)) || setOpenTab(0) ;
        document.addEventListener('click', onClick);
        return () => document.removeEventListener('click', onClick);
    }, []);

    useEffect(() => {
        document.addEventListener('scroll', scrollHandler);
        return function () {
            document.removeEventListener('scroll', scrollHandler);
        };
    },[]);

    const scrollHandler = (e) => {
        if(e.target.documentElement.scroll){
            setOpenTab(0);
        }
    }


    return(
        <>
            <div className='h-[70px]'>
            <div className='header-wrapper'>
                <div className='flex flex-row items-center'>
            <img className='h-[60px] w-[76px] mr-[30px]' src={logo} alt='logo'></img>
            <div className='w-[212px]'> <p className='header-p'>Международная летняя школа «Профессиональные навыки юриста: путь к успеху»</p> </div>
                </div>
                <div className='flex flex-row items-center pr-[60px]'>
                <p className='header-p-light cursor-pointer mr-[40px]' onClick={(e) => setOpenTab(1)} ref={rootEl1}>О школе</p>
                <p className='header-p-light cursor-pointer' onClick={(e) => setOpenTab(2)} ref={rootEl2}>Участие в проекте</p>
                </div>
                <button disabled className='call-us-btn'></button>
            </div>
            <div className='flex justify-center'>
                { (opentab !== 0) &&
            <div className='setterblockHeader' ref={rootEl}>
                { opentab === 1 &&
                    <>
                    <p className='footer-p--black cursor-pointer' id='administration' onClick={props.navigationPages}>Администрация и преподаватели</p>
                    <p className='footer-p--black cursor-pointer' id='schools' onClick={props.navigationPages}>Файлы школы</p>
                    <p className='footer-p--black cursor-pointer' id='photo' onClick={props.navigationPages}>Фотографии</p>
                    </>
                }
                { opentab === 2 &&
                    <>
                        <p className='footer-p--black cursor-pointer' id='conditions' onClick={props.navigationPages}>Условия подачи</p>
                        <p className='footer-p--black cursor-pointer' id='housing' onClick={props.navigationPages}>Информация о проживании</p>
                        <p className='footer-p--black cursor-pointer' id='lessons' onClick={props.navigationPages}>Виды занятий</p>
                        <p className='footer-p--black cursor-pointer' id='certificates' onClick={props.navigationPages}>Дипломы и сертификаты</p>
                    </>
                }
            </div>
                }
            </div>
            </div>
        </>
    );
};

export default Header;