import React, {useEffect, useState} from "react";
import './../componentsHomepage/homepagestyles.scss'
import Serv from "../../services/serv";
import Newswrapper from "../componentsNews/Newswrapper";
import AdministratorWrapper from "./AdministratorWrapper";
import AdministrationProfile from "./AdministrationProfile";

const Administration = (props) => {

    const serv = new Serv()

    const [teachers, setTeachers] = useState([])
    const [teachersRender, setTeachersRender] = useState(0)
    const [teachersPageToRender, setTeachersPageToRender] = useState(0)

    useEffect(() => {
        serv.getAdministrationInfo().then(res => {
            setTeachers(res.data.teachers)
        });
    }, []);

    const elementsAdm = teachers.map((item, i) => {
        if(item.role === 'admin'){
        return (<AdministratorWrapper data={item} key={i} setTeachersRender={() => setTeachersRender(1)} setTeachersPageToRender={setTeachersPageToRender}/>)
        }
    })

    const elementsTeachers = teachers.map((item, i) => {
        if(item.role === 'teacher'){
            return (<AdministratorWrapper data={item} key={i} setTeachersRender={() => setTeachersRender(1)} setTeachersPageToRender={setTeachersPageToRender}/>)
        }
    })


    return(
        <>
            {teachersRender === 0 &&
            <div className='FormWrapper'>
                <div className='flex'><p className='navigationFont underline pr-[6px] cursor-pointer' id='home' onClick={props.navigationPages}>Главная  </p> <p className='navigationFont'>> О школе&ensp;</p>  <p className='navigationFont'>> Администрация и преподаватели</p></div>
                <p className='bigH--40 w-[95%] pt-[45px]'>Администрация школы</p>
                <div className='flex justify-between flex-wrap w-[95%]'>
                    {elementsAdm}
                </div>
                <p className='bigH--40 w-[95%] pt-[45px]'>Преподаватели</p>
                <div className='flex justify-between flex-wrap w-[95%]'>
                    {elementsTeachers}
                </div>
            </div>
            }
            {teachersRender === 1 &&
                <AdministrationProfile id={teachersPageToRender} navigationPages={() => setTeachersRender(0)}/>
            }
        </>
    );
};

export default Administration;