import React, {useEffect, useState} from "react";
import './../componentsHomepage/homepagestyles.scss'
import news from "../../img/newsphoto.svg";

const Newswrapper= (props) => {

    const {title, image_path, date, id} = props.data;

    function showNews(){
        props.setNewsRender()
        props.setNewsPageToRender(id)
    }


    return(
        <>
            <div className={'news-wrapper ' + (props.margin ? 'mr-[20px]' : '')} onClick={showNews}>
                <img src={image_path} className={'news-img  cursor-pointer '} alt='NEWS'/>
                <div className='dateblock'><p >{date}</p></div>
                <div className='whiteblock'>
                    <p className='cursor-pointer'>{title}</p>
                    <span className='navigationFont cursor-pointer'>{date}</span>
                </div>
            </div>
        </>
    );
};

export default Newswrapper;