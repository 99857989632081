import React, {useEffect, useState} from "react";
import './../componentsHomepage/homepagestyles.scss'
import Serv from "../../services/serv";
import PhotosWrapper from "./PhotosWrapper";

const Photos = (props) => {

    const serv = new Serv()

    const [photos, setPhotos] = useState([])

    useEffect(() => {
        serv.getPhotosPage().then(res => {
            setPhotos(res.data.gallery)
        });
    }, []);

    const elementsPhots = photos.map((item, i) => {
        return (<PhotosWrapper data={item} key={i} />)
    })


    return(
        <>
            <div className='FormWrapper'>
                <div className='flex'><p className='navigationFont underline pr-[6px] cursor-pointer' id='home' onClick={props.navigationPages}>Главная  </p> <p className='navigationFont'>> О школе&ensp;</p>  <p className='navigationFont'>> Фотографии</p></div>
                <p className='bigH--40 w-full pt-[50px]'>Галерея</p>
                <div className='flex justify-between flex-col '>
                    {elementsPhots}
                </div>
            </div>
        </>
    );
};

export default Photos;