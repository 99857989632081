import React, {useEffect, useState} from "react";
import './../componentsHomepage/homepagestyles.scss'

const ExtraEducation = (props) => {

    const {years, university, prop, type} = props.data;

    return(
        <>
            <div className='education'>
                <div className='infoHead'>
                    <p className='bigH--18'>{type ? type : years}</p>
                    <p className='header-p-light--18'>{university}</p>
                </div>
                <div className='infoDiscr'>
                    <p className='header-p-light'>{prop}</p>
                </div>
            </div>
        </>
    );
};

export default ExtraEducation;