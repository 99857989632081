import React from "react";
import './../componentsHomepage/homepagestyles.scss'

const PhotosWrapper = (props) => {

    const {date, image_path} = props.data;

    function ViewPhoto(e){
        window.open(e.target.src);
    }

    return(
        <>
        <div>
            <p className='navigationFont pt-[50px]'>{date}</p>
            <div className='flex justify-between flex-wrap pt-[5px]'>
                {
                    image_path.map((item) => {
                        return (<><div className='boxPhotos'> <img src={item} onClick={ViewPhoto} className='cursor-pointer' alt='photo'/> </div></>)
                    })
                }
            </div>
            </div>

        </>
    );
};

export default PhotosWrapper;