import React, {useEffect, useState} from "react";
import './../componentsHomepage/homepagestyles.scss'
import Serv from "../../services/serv";
const Housing= (props) => {
    const serv = new Serv();
    const [text, setText] = useState([])
    useEffect(() => {
        serv.getHousingInfo().then(res => {
            setText(res.data.text)
        });
    },[]);

    return(
        <>
            <div className='textPagesWrapper'>
                <div className='flex'><p className='navigationFont underline pr-[6px] cursor-pointer' id='home' onClick={props.navigationPages}>Главная  </p> <p className='navigationFont'>> Участие в проекте&ensp;</p>  <p className='navigationFont'>> Информация о проживании</p></div>
                <div className='textPages'>
                <p className='bigH--1'>Проживание</p>
                <p className='header-p-light mt-[20px]'>Проезд и проживание иногородние участники оплачивают самостоятельно или за счет направляющей стороны. Проживание в период обучения в школе может быть организовано участниками самостоятельно, а также оргкомитетом Летней школы.  </p>
                <p className='bigH--16px mt-[30px]'> Срок проживания рассчитывается следующим образом: </p>
                    <div className='flex w-full justify-center mt-[20px]'><p className='header-p-light bg-[#fef8ef] px-[10px] py-[5px]'>{text.date_info}</p></div>
                <p className='header-p-light mt-[30px]'>{text.date_value}</p>

                    <div className='quickNews mt-[10px]'>
                        <p className='mr-[500px]'>.</p>
                        <div>
                            <div className='newsrow'>
                                <div className='flex justify-start flex-col w-[1060px]'>
                                <p className='bigH--16px pt-[30px]'>Стоимость проживания</p>
                                <p className='header-p-light pt-[30px]'>&bull; {text.price}</p>
                                <p className='header-p-light pt-[30px]'>{text.hotel_info}</p>
                                <p className='bigH--16px pt-[20px]'>Адрес общежития БФУ им. И. Канта: Калининград, ул. Невского, 14Б</p>
                                <p className='bigH--16px py-[26px]'>{text.hotel_address}</p>
                                </div>
                            </div>
                        </div>
                    </div>



                    <p className='bigH--1 mt-[45px] mb-[20px]'>Питание</p>
                    <p className='header-p-light mb-[60px]'>Во время образовательных мероприятий Летней школы проходит организованно строго в обозначенное в расписании Школы время в ресторане рядом с университетом. В целях своевременного обеспечения питания оплату за обеденное питание оргкомитет принимает во время регистрации : {text.eat_price}
                        <br/> <br/>
                        Завтраки и ужины, перекусы, дополнительно воду студенты организовывают самостоятельно.</p>
                </div>
            </div>
        </>
    );
};

export default Housing;