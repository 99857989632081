import React, {useEffect, useState} from "react";
import './../componentsHomepage/homepagestyles.scss'
import Serv from "../../services/serv";
import twostages from './../../img/twostages.svg'


const Conditions= (props) => {
    const serv = new Serv();
    const [text, setText] = useState([])
    useEffect(() => {
        serv.getConditionInfo().then(res => {
            setText(res.data.text)
        });
    },[]);

    return(
        <>
            <div className='textPagesWrapper'>
                <div className='flex'><p className='navigationFont underline pr-[6px] cursor-pointer' id='home' onClick={props.navigationPages}>Главная  </p> <p className='navigationFont'>> Участие в проекте&ensp;</p>  <p className='navigationFont'>> Условия подачи</p></div>
                <div className='textPages'>
                    <p className='bigH--1'>Условия участия в Летней школе</p>
                    <p className='header-p-light mt-[20px]'>Для участия в Летней школе необходимо подать заявку на русском или английском языке, в зависимости от того, в какой языковой группе студент хочет учиться. <br/> <br/>
                        Заявка включает в себя следующие документы:</p>
                    <p className='header-p-light mt-[20px]'>{text.documents}</p>
                    <p className='header-p-light mt-[20px]'>В резюме Вам необходимо указать анкетные данные, образование, дополнительное образование, опыт работы в юридической клинике, опыт участия в конкурсах, других школах, знание иностранных языков и иные навыки или хобби. Документ подается на русском языке, а студентам, претендующим на зачисление в англоязычную группу, необходимо предоставить его на английском языке. </p>
                    <p className='header-p-light mt-[20px]'>Мотивационное письмо предполагает эссе до 200 слов (не более 1 страницы текста), в котором необходимо указать, почему Вы хотите принять участие в Летней школе в БФУ им. И. Канта и почему оргкомитет Школы должен выбрать именно Вас. Как и резюме оно подается на русском языке, но если Вы хотите податься на обучение в англоязычной группе, то вам необходимо прикрепить письмо на англиийском языке. </p>
                    <p className='bigH--16px mt-[30px]'>Отбор студентов происходит в два этапа: </p>
                    <div className='mt-[45px] flex flex-row'>
                        <img src={twostages} alt="logo"/>
                        <div className='ml-[20px] flex flex-col'>
                            <p className='header-p-light mt-[6px]'>Студенты получат подтверждение о приеме в Летнюю школу, и будет дано несколько дней на покупку билетов в Калининград и предоставление в оргкомитет сканов приобретенных билетов.</p>
                            <p className='header-p-light mt-[20px]'>Окончательное зачисление в Летнюю школу будет происходить после истечения срока, данного на предоставление отсканированных копий приобретенных билетов в Калининград, по факту получения этих сканов оргкомитетом.</p>
                        </div>
                    </div>
                    <p className='header-p-light mt-[20px]'>Если студент, прошедший первый этап, не представляет скан билета в указанный оргкомитетом срок, то он теряет право быть зачисленным в Школу, и это право переходит студенту из списка ожидания.</p>
                    <p className='header-p-light mt-[20px]'>{text.terms}</p>
                    <p className='header-p-light mt-[20px]'>{text.term_helper}</p>
                    <div className='w-full flex justify-center'> <button className='participate-btn mt-[30px]' id='formPage' onClick={props.navigationPages}>Подать заявку</button> </div>
                </div>
            </div>
        </>
    );
};

export default Conditions;