import React from "react";
import './../componentsHomepage/homepagestyles.scss'
import phone from './../../img/phone.svg'
import letter from './../../img/letter.svg'
import vk from './../../img/vk.svg'
import telegram from './../../img/telegram.svg'

const Footer = (props) => {
    return(
        <>
            <div className='footer-wrapper'>
                <div className='footer-content'>
                <div className='address-footer'>
                    <p className='footer-h mb-[13px]'>Наш адрес</p>
                    <p className='footer-p mb-[25px]'>г. Калининград, ул. Невского 14</p>
                    <div className='flex flex-row items-center'>

                    </div>
                </div>
                <div className='about-school-footer'>
                    <p className='footer-h mb-[13px]' id='' onClick={props.navigationPages}>О школе</p>
                    <p className='footer-p cursor-pointer' id='administration' onClick={props.navigationPages}>Сотрудники школы</p>
                    <p className='footer-p cursor-pointer' id='schools' onClick={props.navigationPages}>Файлы школы</p>
                    <p className='footer-p cursor-pointer' id='photo' onClick={props.navigationPages}>Фотографии</p>
                </div>
                <div className='participate'>
                    <p className='footer-h mb-[13px]'>Участие в проекте</p>
                    <p className='footer-p cursor-pointer' id='conditions' onClick={props.navigationPages}>Условие подачи заявки</p>
                    <p className='footer-p cursor-pointer' id='housing' onClick={props.navigationPages}>Информация о проживании</p>
                    <p className='footer-p cursor-pointer' id='lessons' onClick={props.navigationPages}>Виды занятий</p>
                    <p className='footer-p cursor-pointer' id='certificates' onClick={props.navigationPages}>Дипломы и сертификаты</p>
                </div>
                <div className='call-us'>
                    <p className='footer-h mb-[13px]'>Связаться с нами</p>
                    <div className='flex flex-row items-center'>
                    <img className='h-[14px] w-[14px] mr-[3%]' src={phone} alt='logo'></img>
                    <p className='footer-p'>+7911-452-00-45</p>
                     </div>
                    <div className='flex flex-row items-center'>
                    <img className='h-[14px] w-[14px] mr-[3%]' src={letter} alt='logo'></img>
                    <p className='footer-p'>bfusummerschool@gmail.com</p>
                    </div>
                </div>
                </div>
            </div>
        </>
    );
};

export default Footer;