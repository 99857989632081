import React, {useEffect, useState} from "react";
import './../componentsHomepage/homepagestyles.scss'
import news from "../../img/newsphoto.svg";
import Serv from "../../services/serv";

const FullNews= (props) => {

    const id = props.id;
    const serv = new Serv()
    const [dataUser, setDataUser] = useState([])

    useEffect(() => {
        if(id !== undefined){
        serv.getNewsPage(id).then(res =>{
            setDataUser(res.data.news)
        });
        }
    },[]);

    return(
        <>
            <div className='FormWrapper'>
                <div className='flex'><p className='navigationFont underline pr-[5px] cursor-pointer' id='home' onClick={props.navigationPages}>Главная </p>  <p className='navigationFont'>> Все новости</p></div>
                <p className='navigationFont w-full pt-[50px]'>{dataUser.date} &bull; время чтения {dataUser.time} минут(ы)</p>

                <div className='textPages px-[120px]'>
                    <img src={dataUser.image_path} alt='logo' className='w-full'/>
                    <p className='bigH--40 mt-[30px]'>{dataUser.title}</p>
                    <p className='header-p-light--1 my-[40px]'>{dataUser.description}</p>
                </div>
                </div>
        </>
    );
};

export default FullNews;